import React from "react";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function TermsRedirect() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (isLoggedIn) toast.error("Please accept the Terms of Use and Service");
  }, []);

  return <Navigate to="/" replace />;
}
