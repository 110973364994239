import authHeader from "../services/auth-header";
import { apiFetchWrapper } from "../utils/axiosApiWrapper";

const API_URL = process.env.REACT_APP_BACK_END_URL;

const createPersona = (payload) => {
  return apiFetchWrapper.post(
    API_URL + "personas/entities/",
    { ...payload },
    { headers: authHeader() }
  );
};

const entityService = {
  createPersona,
};
export default entityService;
