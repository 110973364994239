const actionSelecetd = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.5V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16.172C16.7024 3.00011 17.211 3.2109 17.586 3.586L20.414 6.414C20.7891 6.78899 20.9999 7.29761 21 7.828V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V16.5"
        fill={process.env.REACT_APP_LIGHT_PRIMARY}
      />
      <path
        d="M3 7.5V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16.172C16.7024 3.00011 17.211 3.2109 17.586 3.586L20.414 6.414C20.7891 6.78899 20.9999 7.29761 21 7.828V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V16.5"
        stroke={process.env.REACT_APP_LIGHT_PRIMARY}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12H16M16 12L13 9M16 12L13 15"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default actionSelecetd;
