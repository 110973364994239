import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Settings from "./components/settings";
import Statistics from "./pages/Filed applications/Analytics view";
import {
  CompleteQuestionnaire,
  FileApplication,
  Introduction,
  Notifications,
  Terms,
  Workflow,
} from "./pages";
import Layout from "./components/Layout";
import Questionnaire from "./pages/ActionRequired/Questionnaire";
import PowerOfAttorney from "./pages/ActionRequired/PowerOfAttorney";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/style.scss";
import TermsRedirect from "./components/common/TermsRedirect";
import { Faq } from "./pages/Faq";
import SSOLogin from "./components/SSOLogin";
import {
  primary2ndBgColor,
  primaryColor,
  primaryDisabledColor,
} from "./common/env";
import { RemoteWork } from "./pages/Filed applications/Remote requests";
import { AnalyticsProvider } from "./pages/Filed applications/Analytics view/analyticsContext";
import { QuestionnaireProvider } from "./pages/ActionRequired/Questionnaire/context";

const App = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { sideBarHide } = useSelector((state) => state.layout);

  const [hasAcceptedTermOfServices, setHasAcceptedTermOfServices] =
    React.useState(localStorage.getItem("termsofservices"));

  useEffect(() => {
    document.documentElement.style.setProperty("--primaryColor", primaryColor);
    document.documentElement.style.setProperty(
      "--primaryDisabledColor",
      primaryDisabledColor
    );
    document.documentElement.style.setProperty(
      "--primary2ndBgColor",
      primary2ndBgColor
    );
  }, []);
  return (
    <Router>
      <div
        id={isLoggedIn ? "wrapper" : ""}
        className={!sideBarHide ? "mystyle" : "openSideBarStyle"}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Introduction
                  isChecked={hasAcceptedTermOfServices}
                  setIsChecked={setHasAcceptedTermOfServices}
                />
              </Layout>
            }
          />
          <Route path="/login" element={<SSOLogin />} />
          <Route
            path="/map-view"
            element={
              <Layout>
                <AnalyticsProvider>
                  <Statistics />
                </AnalyticsProvider>
              </Layout>
            }
          />
          <Route
            path="/questionnaire"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <QuestionnaireProvider>
                    <Questionnaire />
                  </QuestionnaireProvider>
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/complete-questionnaire"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <CompleteQuestionnaire />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/sign-power-of-attorney"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <PowerOfAttorney />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/file-applications"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <FileApplication />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/remote-requests"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <RemoteWork />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/settings"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />
          <Route
            path="/notifications"
            element={
              <Layout>
                <Notifications />
              </Layout>
            }
          />
          <Route
            path="/workflow"
            element={
              <Layout>
                <Workflow />
              </Layout>
            }
          />
          <Route
            path="/terms"
            element={
              <Layout>
                <Terms />
              </Layout>
            }
          />
          <Route
            path="/faq"
            element={
              <Layout>
                <Faq />
              </Layout>
            }
          />
        </Routes>
      </div>
      <ToastContainer />
    </Router>
  );
};

export default App;
