import React, { useEffect, useRef, useState } from "react";
import { MyModal, NormalText, SelectBox } from "../../../../components/common";
import Icon, { ICON_NAMES } from "../../../../components/Icone";
import { primaryColor } from "../../../../common/env";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useCountryList from "../../../../assets/shared/hooks/useCountryList";
import entityService from "../../../../services/entity.service";
import styles from "../index.module.css";
import ProgressLoading from "../../../../components/ProgressLoading";
import dashboardService from "../../../../services/dashboard.service";
import remove from "../../../../assets/images/delete.svg";
const duration = 60;

const AddEntitiesModal = ({
  setSaveLoading,
  handleGetForm,
  setAddEntitiesModal,
  getPersonaEntitiesHandler,
  saveLoading,
}) => {
  const { personaEntities, countriesList, singleLoading } = useSelector(
    (state) => state.dashboard
  );
  const { getCountryName, getCountryCode } = useCountryList();

  const [arrangedCountryList, setArrangedCountryList] = useState([]);
  const [adressList, setAdressList] = useState([]);
  const [adressAdded, setAdressAdded] = useState({});
  const [addedIndex, setAddedIndex] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [personaData, setPersonaData] = useState({});
  const [abortController, setAbortController] = useState(null);

  const intervalId = useRef(null); // Using ref to store the interval ID
  const apiCallCompleted = useRef(false); // Ref to store the dynamic value

  const clearLoaderHandler = () => {
    setSaveLoading(false);
    setPercentage(0);
    apiCallCompleted.current = false;
    clearInterval(intervalId.current);
    if (abortController) {
      abortController.abort();
      console.log("API call canceled by user");
      setAbortController(null);
    }
  };

  const handleRemoveAdress = (adress) => {
    setAdressList(
      adressList.filter((item) => item?.house_number !== adress?.house_number)
    );
  };
  const addAddressHandler = (adress, itemIndex) => {
    setAddedIndex([itemIndex]);
    setAdressAdded(adress);
  };
  const handleAddAdress = async (adress, itemIndex) => {
    setSaveLoading(true);
    try {
      const res = await entityService.createPersona({
        name: adressAdded.employer_name,
        country: personaData.country,
        compliance_type: ["A1", "MSW-A1", "COC"],
      });
      if (res?.error) {
        toast.error(res?.message || "Error processing this request");
      }
      if (!res?.error) {
        const countryName = arrangedCountryList?.find(
          (_item) => _item.value === personaData.country
        )?.label;
        handleGetForm(
          {
            ...res.data,
            country_name: countryName,
            country_code: getCountryCode(countryName),
            persona_name: res.data.name,
          },
          adressAdded,
          false
        );
        setAddEntitiesModal(false);
        setPersonaData({});
        setAdressList([]);
        // setSortCondition({});
      }
    } catch (error) {
      console.error(error);
    }
    setSaveLoading(false);
  };

  const handleManualEntityAddition = async (name, country) => {
    try {
      const res =
        (await entityService.createPersona({
          name: name,
          country: country,
          compliance_type: ["A1", "MSW-A1", "COC"],
        })) || {};

      if (res.error) {
        toast.error(res.message);
        return;
      }
      const data = res.data;

      getPersonaEntitiesHandler();
      const selectedCountry = countriesList.find(
        (_item) => _item.uuid === country
      );
      data.country_code = getCountryCode(selectedCountry.name);
      data.country_name = selectedCountry.name;
      handleGetForm(data, {}, false);
      setAddEntitiesModal(false);
      setPersonaData({});
      setAdressList([]);
      // setSortCondition({});
    } catch (error) {
      console.error(error);
    }
  };

  const searchAddressesHandler = async (data) => {
    setSaveLoading(true);

    const totalSteps = 100; // Total percentage to animate to
    const intervalTime = (duration * 1000) / totalSteps; // Time per percentage point
    intervalId.current = setInterval(() => {
      const isCompleted = apiCallCompleted.current;
      setPercentage((prev) => {
        if (isCompleted && prev > 0 && prev < 98) {
          return 98;
        } else if (prev < totalSteps) {
          return prev + 1;
        } else {
          clearLoaderHandler();
          return 0;
        }
      });
    }, intervalTime);

    try {
      let query = `company_name=${personaData.name}`;
      const countryName = countriesList.find(
        (_item) => _item.uuid === personaData.country
      );
      if (countryName?.name) {
        query += `&country_code=${getCountryCode(countryName?.name)}`;
      }

      const controller = new AbortController();
      setAbortController(controller);
      console.log("query", query);
      const res = await dashboardService.searchAddress(
        query,
        controller.signal
      );
      apiCallCompleted.current = true;

      setAbortController(null);
      if (res.error) {
        toast.error(
          res.message ||
            "Could not fetch country data. Please try by adding country manually"
        );
        setAdressList([]);
        return;
      }
      setAdressList(res.data);
    } catch (error) {
      setAdressList([]);
      console.error(error);
      clearLoaderHandler();
    }
  };

  const handleModalClose = () => {
    setPersonaData({});
    setAddEntitiesModal(false);
    setAdressList([]);
  };

  const onChangeHandler = (name, value) => {
    const data = { ...personaData };

    if (name === "contact") {
      if (data[name]) {
        data[name] = {
          ...data[name],
          ...value,
        };
      } else {
        data[name] = value;
      }
    }

    if (name === "name" || name === "country") data[name] = value;
    setPersonaData(data);
  };

  useEffect(() => {
    if (!singleLoading) {
      setPersonaData({});
    }
  }, [singleLoading]);

  useEffect(() => {
    if (countriesList && countriesList.length) {
      setArrangedCountryList(
        countriesList.map((_item) => {
          return { label: _item.name, value: _item.uuid };
        })
      );
    }
  }, [countriesList]);
  return (
    <MyModal>
      {saveLoading && (
        <ProgressLoading
          percentage={percentage}
          clearLoaderHandler={clearLoaderHandler}
        />
      )}
      <div className="w-100 h-100" style={{ maxHeight: "54rem" }}>
        <div
          className="d-flex align-items-center justify-content-between flex-wrap"
          style={{ gap: 7 }}
        >
          <h2 style={{ marginBottom: 0 }}>Add entity details</h2>
          <button
            style={{
              backgroundColor: primaryColor,
              borderColor: primaryColor,
            }}
            disabled={!(personaData.name && personaData.country)}
            type="button"
            className="btn btn-danger"
            onClick={searchAddressesHandler}
            loading={true}
          >
            Search
          </button>
        </div>
        <div className={`${styles.entitySelectContainer} w-100`}>
          <div style={{ width: "100%" }}>
            <SelectBox
              showIcons={false}
              value={personaData.country}
              onChange={(country) => {
                onChangeHandler("country", country?.value);
              }}
              options={arrangedCountryList}
            />
          </div>
          <div style={{ width: "100%" }}>
            <label className="form-label">
              Name of employer
              <span style={{ color: primaryColor }}>*</span>
            </label>
            <input
              value={personaData.name}
              onChange={(e) => onChangeHandler("name", e.target.value)}
              className="form-control"
              placeholder="Enter employer name"
            />
          </div>
        </div>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "28rem",
            marginTop: "1rem",
            padding: "0 1rem",
          }}
        >
          {adressList?.map((item, key) => {
            return (
              <div className="mt-5" key={key}>
                <div className="d-flex justify-content-between flex-wrap">
                  <h5> {item.employer_name}</h5>
                  <div className="d-flex">
                    {!addedIndex?.includes(key) && (
                      <div
                        style={{
                          color: "#A51E22",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        className="cursor-pointer d-flex justify-content-center align-items-center text-center pe-2 border-end"
                      >
                        <img
                          src={remove}
                          alt="remove"
                          height={"15px"}
                          className="me-1"
                        />
                        <NormalText
                          onClick={() => handleRemoveAdress(item)}
                          color="red"
                        >
                          Remove
                        </NormalText>
                      </div>
                    )}
                    <div
                      style={{
                        color: "#1B52A4",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      onClick={() =>
                        !addedIndex?.includes(key) &&
                        addAddressHandler(item, key)
                      }
                      className="cursor-pointer ps-2 d-flex justify-content-center align-items-center text-center border-left border-grey "
                    >
                      {!addedIndex?.includes(key) && (
                        <div className="me-1">
                          <Icon name={ICON_NAMES.ADDICONE} />
                        </div>
                      )}
                      <NormalText color="theme">
                        {addedIndex?.includes(key)
                          ? "Selected"
                          : "Click to select"}
                      </NormalText>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <span> Address: </span>

                  <div className="px-1">
                    {item.employer_address
                      ? item.employer_address
                      : (item.employer_city ? item.employer_city : "") +
                        " " +
                        (item.employer_street_name
                          ? item.employer_street_name
                          : "")}
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </div>

        <div
          style={{ flexWrap: "wrap" }}
          className="mt-5 d-flex w-100 justify-content-center gap-3"
        >
          <button
            style={{
              color: primaryColor,
              borderColor: primaryColor,
              backgroundColor: "white",
            }}
            type="button"
            className="btn"
            onClick={handleModalClose}
          >
            Cancel
          </button>
          <button
            style={{
              color: primaryColor,
              borderColor: primaryColor,
              backgroundColor: "white",
            }}
            disabled={!(personaData.name && personaData.country)}
            type="button"
            className="btn "
            onClick={() => {
              handleManualEntityAddition(personaData.name, personaData.country);
            }}
          >
            Add manually
          </button>
          <button
            style={{
              backgroundColor: primaryColor,
              borderColor: primaryColor,
            }}
            disabled={Object.keys(adressAdded)?.length ? false : true}
            type="button"
            className="btn btn-danger"
            onClick={() => {
              handleAddAdress();
            }}
          >
            Add
          </button>
        </div>
      </div>
    </MyModal>
  );
};

export default AddEntitiesModal;
