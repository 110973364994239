export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("AUTH0_TOKEN_RESPONSE"));
  const tenant = localStorage.getItem("tenantsInfo");
  const tanentInfo = tenant ? JSON.parse(tenant) : {};
  if (user && user.id_token) {
    return {
      Authorization: `Bearer ${user.id_token}`,
      Version: tanentInfo.tenant,
    };
  } else {
    return { Version: tanentInfo.tenant };
  }
}
