export const ICON_NAMES = {
  INTROSELECTED: "introSelected",
  INTROLIGHT: "introLight",
  ACTIONREQUIREDLIGHT: "actionRequiredLight",
  ACTIONSELECTED: "actionSelecetd",
  MAP: "map",
  MAPSELECTED: "mapSelected",
  DROPDOWNSELECTED: "dropDownSelected",
  DROPDOWNSELECTEDLIGHT: "dropDownSelectedLight",
  DROPDOWNLIGHT: "dropDownLight",
  LIGHTTHEMEDROPDOWN: "lightThemeDropDown",
  ADDICONE: "addIcone",
  EDIT: "edit",
  DELETICONE: "deleteIcone",
  CROSSICONE: "crossIcone",
};

export default ICON_NAMES;
