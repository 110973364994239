import { keys, size } from "lodash";
import React from "react";
import actiondone from "../../../../assets/images/action_done.svg";
import incomplete from "../../../../assets/images/not_complete.svg";
import inprogress from "../../../../assets/images/in_progress.svg";
import completeBar from "../../../../assets/images/progress_red.svg";
import incompleteBar from "../../../../assets/images/progress_blue.svg";
import inprogressBar from "../../../../assets/images/half_progress.svg";

const Steps = ({ stepsGroupObj, currentSelectedGroup }) => {
  const temoObj = stepsGroupObj;
  return (
    <>
      <section className="bg-white  text-black  border-bottom w-100">
        <div className="d-flex flex-wrap justify-content-center step-bar">
          {keys(temoObj).map((item, index) => {
            const groupNumber = parseInt(item);
            if (groupNumber <= size(keys(temoObj)))
              return (
                <div
                  className="d-flex flex-column justify-content-center align-items-start"
                  key={index}
                >
                  <div className="d-flex">
                    <img
                      src={
                        currentSelectedGroup < groupNumber
                          ? incomplete
                          : currentSelectedGroup > groupNumber
                          ? actiondone
                          : inprogress
                      }
                      alt={actiondone}
                    />
                    {index !== keys(temoObj).length - 1 && (
                      <img
                        src={
                          currentSelectedGroup < groupNumber
                            ? incompleteBar
                            : currentSelectedGroup > groupNumber
                            ? completeBar
                            : inprogressBar
                        }
                        alt={completeBar}
                      />
                    )}
                  </div>
                  <div className="py-2">{temoObj[parseInt(item)]}</div>
                </div>
              );
          })}
        </div>
      </section>
    </>
  );
};

export default Steps;
