import React from "react";

export const TitleText = ({ children, restStyle = "",style }) => {
  const divStyle = {
    color: "#1E3542",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "18px",
    ...style
  };
  return (
    <div style={divStyle} className={`d-flex  ${restStyle}`}>
      {children}
    </div>
  );
};
