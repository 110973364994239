const lightThemeDropDown = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9375 6.46533L9 11.5278L14.0625 6.46533"
        stroke={process.env.REACT_APP_LIGHT_PRIMARY}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default lightThemeDropDown;
