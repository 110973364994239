import React from "react";
import { Link } from "react-router-dom";
import fbLogo from "../assets/images/fb.svg";
import linkedinLogo from "../assets/images/linkedin.svg";
import instagramLogo from "../assets/images/instagram.svg";
import twitterLogo from "../assets/images/twitter.png";
import { socialLinks } from "../common/constants";

function Footer() {
  const emailAddress = "assure@cibtvisas.com";
  const subject = "Question about A1";

  const handleEmailClick = () => {
    window.open(
      `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`,
      "_blank"
    );
  };
  return (
    <footer>
      <div className="container-fluid">
        <div className="footerFlex">
          <ul style={{ gap: 15 }}>
            <li>
              <Link style={{ color: "white", cursor: "pointer" }} to="/faq">
                FAQ
              </Link>
            </li>
            {/* <li>
								<Link to="#">About us</Link>
							</li> */}
            <li>
              <Link style={{ color: "white", cursor: "pointer" }} to="/terms">
                Terms of service
              </Link>
            </li>
            {/* <li>
								<Link to="#">Terms of use</Link>
							</li> */}
            <li>
              <Link
                onClick={() => handleEmailClick()}
                style={{ color: "white", cursor: "pointer" }}
                // to="/terms"
              >
                Contact us
              </Link>
            </li>
          </ul>

          <ul className="social-icon justify-content-lg-end">
            <li>
              <Link to={socialLinks.facebookLink} target="_blank">
                <img src={fbLogo} alt="fb" />
              </Link>
            </li>
            <li>
              <Link to={socialLinks.linkedinLink} target="_blank">
                <img src={linkedinLogo} alt="tw" />
              </Link>
            </li>
            <li>
              <Link to={socialLinks.twitterLink} target="_blank">
                <img src={twitterLogo} alt="twitterLogo" />
              </Link>
            </li>
            <li>
              <Link to={socialLinks.instagramLink} target="_blank">
                <img src={instagramLogo} alt="instagramLogo" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <p
        style={{
          color: "#fff",
          textAlign: "center",
          marginTop: 10,
          fontWeight: "bold",
          marginBottom: 0,
        }}
      >
        Powered by the Cozm
      </p>
    </footer>
  );
}

export default Footer;
