import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { isEqual, startsWith } from "lodash";
import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { FieldType } from "../../../common/constants";
import { HelpText } from "../HelpText";
import { getDateDisplayFormat } from "../../../common/helper/formateDate";
import { TitleText } from "../../TitleText";
import Close from "../../../assets/images/close.svg";

export const FormDate = (props) => {
  const {
    value = "2023-08-02",
    description = "",
    // placeholder = 'DD/MM/YYYY',
    isRequired = false,
    error = "",
    showError = false,
    helpText = "",
    type = "",
    name = "",
    onChange = () => {},
    autoPopulatedValues,
    setAutoPopulatedValues,
  } = props;
  const [missingField, setMissingField] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const handleChange = useCallback(
    (date) => {
      setIsTouched(true);
      if (!!date) {
        const output = getDateDisplayFormat(date);
        onChange(name, output, type);
      } else {
        onChange(name, "", type);
      }
      if (autoPopulatedValues?.[name]) {
        setAutoPopulatedValues((prev) => {
          const { [name]: _, ...rest } = prev;
          return rest;
        });
      }
    },
    [name, onChange, type]
  );

  useEffect(() => {
    if (!!error && isTouched) setDisplayError(true);
    else setDisplayError(false);
  }, [error, isTouched]);

  const currentDate = dayjs();
  // const currentDatei = new Date()
  const isDateOfBirth = isEqual(type, FieldType.DATE_OF_BIRTH);

  const isDateFromFuture =
    startsWith(type, "date_range_future") ||
    isEqual(type, FieldType.DATE_DISABLED) ||
    isEqual(type, FieldType.DATE_FUTURE);

  const isDateFromPast =
    startsWith(type, "date_range_past") ||
    isEqual(type, FieldType.DATE_DISABLED) ||
    isEqual(type, FieldType.DATE_PAST) ||
    isEqual(type, FieldType.DATE_PAST_SAME);

  const minDate = isDateFromFuture ? currentDate : null;

  const maxDate = isDateFromPast
    ? currentDate
    : isDateOfBirth
    ? dayjs().subtract(18, "year")
    : null;

  const dateOfBirth = value;
  const parsedDate = dayjs(dateOfBirth, "YYYY-MM-DD", true); // Parse with strict mode
  const isValidDate = parsedDate.isValid();

  useEffect(() => {
    if (name && !value && isRequired) {
      setMissingField(true);
    } else {
      setMissingField(false);
    }
  }, [value, isRequired, name]);

  return (
    <>
      <div
        style={{
          borderBottom: "2px solid #CECECE",
        }}
        className="position-relative d-flex flex-column w-100 justify-content-between h-100"
      >
        <div className="d-flex align-items-start mb-2">
          <label htmlFor={name} className="d-flex">
            <TitleText
              style={
                (error && displayError) || missingField
                  ? { color: "#a51e22" }
                  : {}
              }
              children={`${description}${
                !isRequired && description ? " (optional)" : ""
              }`}
            />
            {/* {isRequired && <Sterik />} */}
            {/* {!isRequired && "optional"} */}
          </label>
          {helpText && <HelpText helpText={helpText} className="pl-2" />}
        </div>
        <div className="d-flex justify-content-between align-items-center border-bottom-2 w-100  border-C3">
          <div className="position-relative w-100">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  style={{
                    lineHeight: "18px",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                  format="DD-MM-YYYY"
                  minDate={minDate}
                  maxDate={maxDate}
                  // placeholder={placeholder}
                  value={value ? dayjs(value) : null}
                  // disabled={type === "date_disabled"}
                  name={name}
                  className={`date-input placeholder-style value-style focus-visible:outline-none font-medium text-17px leading-18px block w-100 ${
                    autoPopulatedValues && autoPopulatedValues?.[name]
                      ? "prefilled-color"
                      : "not-prefilled-color"
                  }`}
                  onChange={(date) => {
                    if (date) handleChange(date.toDate());
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          {value && (
            <img
              className="bi-close cursor-pointer ms-2"
              src={Close}
              alt="close"
              onClick={() => onChange(name, "")}
            />
          )}
        </div>
      </div>
      {error && displayError && (
        <div
          className={`text-danger bottom-25% left-0 right-0 text-start focus-visible:outline-none h-10px sm:h-15px w-full text-12px sm:text-14px lg:text-16px leading-15px sm:leading-17px lg:leading-19px ${
            displayError ? "visible" : "invisible"
          }`}
        >
          {!isValidDate && value ? "Please add a valid date" : error}
        </div>
      )}
    </>
  );
};
