export function formatToDateTime(milliseconds) {
  const date = new Date(milliseconds);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export const getDateDisplayFormat = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const checkIsNumeric = (value) => {
  const isNumeric = /^[+-]?\d+(\.\d+)?$/.test(value);
  if (isNumeric) {
    return true;
  } else {
    return false;
  }
};

export const findNestedFieldByName = (arr, fieldName) => {
  for (const item of arr) {
    if (item.name === fieldName) {
      return item;
    }

    if (item.conditional_fields) {
      const result = findNestedFieldByName(item.conditional_fields, fieldName);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
