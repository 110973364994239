import React, { useEffect, useMemo, useRef, useState } from "react";
import MapsAndGraphs from "./staticsticsMapsAndGraph.jsx";
import StaticsticsCard from "./StaticsticsCard";
import StaticsticsTable from "./StaticsticsTable.jsx";
import { useDispatch, useSelector } from "react-redux";
import { TableLaoding } from "../../../components/common/Loading/TableLoading.js";
import { MapFilter } from "./filter.jsx";
import styles from "./styles.module.css";
import { useAnalyticsFilter } from "./analyticsContext.jsx";
import getQueryParamsFromObj from "../../../utils/getParamsFromObj.js";
import {
  getApplicationsStats,
  updateFilter,
} from "../../../slices/filledApplications.js";
import { API_URL } from "../../../common/env.js";

const RadioOption = ({ label, value, selectedOption, onChange }) => {
  const inputRef = useRef(null);

  const handleDivClick = () => {
    if (selectedOption === value) {
      onChange(null);
    } else {
      inputRef.current.click();
    }
  };

  return (
    <div className={styles.statistics_Radio} onClick={handleDivClick}>
      <input
        type="radio"
        value={value}
        checked={selectedOption === value}
        onChange={() => onChange(value)}
        ref={inputRef}
        className={`${styles.statistics_Radio_Input} ${
          value === "homeCountries" && styles.statistics_Radio_red
        }`}
      />
      <p>{label}</p>
    </div>
  );
};

const Statistics = () => {
  const { isLoading, filters, isFilterCall } = useSelector(
    (state) => state.filledApplications
  );
  const { filterData } = useAnalyticsFilter();
  const [homeCountrySelected, setHomeCountrySelected] = useState(false);
  const [hostCountrySelected, setHostCountrySelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getApplicationsStats());
  }, [dispatch]);

  useEffect(() => {
    if (isFilterCall !== null) {
      let searchQuery = "";
      const keys = Object.keys(filters);

      if (keys.length) {
        keys.forEach((key, index) => {
          if (filters[key]) {
            if (!searchQuery) {
              searchQuery = `?${key}=${filters[key]}`;
            } else {
              searchQuery += `&${key}=${filters[key]}`;
            }
          }
        });
      }

      dispatch(getApplicationsStats(searchQuery));
    }
  }, [isFilterCall, dispatch, filters, API_URL]);

  const getQuery = useMemo(() => {
    let query = "";
    if (homeCountrySelected) query = "country_type=home_country";
    else if (hostCountrySelected) query = "country_type=host_country";
    else query = "";

    return query;
  }, [homeCountrySelected, hostCountrySelected]);

  useEffect(() => {
    const query = getQuery;

    dispatch(
      updateFilter({
        reset: true,
      })
    );
    const getFilterQuery = getQueryParamsFromObj(filterData);
    const combinedQuery = [getFilterQuery, query].filter(Boolean).join("&");

    dispatch(getApplicationsStats(combinedQuery));
  }, [getQuery, dispatch]);

  const handleOptionChange = (value) => {
    if (value === null) {
      setSelectedOption(null);
      setHomeCountrySelected(false);
      setHostCountrySelected(false);
    } else {
      setSelectedOption(value);
      if (value === "homeCountries") {
        setHomeCountrySelected(true);
        setHostCountrySelected(false);
      } else {
        setHomeCountrySelected(false);
        setHostCountrySelected(true);
      }
    }
  };
  return (
    <div
      className="mt-3"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        maxWidth: "100rem",
        width: "100%",
      }}
    >
      {isLoading ? (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
          style={{
            zIndex: "9999",
          }}
        >
          <TableLaoding />
        </div>
      ) : null}
      <div className="container-fluid mb-5 mb-lg-2">
        <div className="row">
          <div className="col-12 col-lg-6 ">
            <h4>Analytics view</h4>
            {/* HOME/DESTINATION COUNTRY RADIO */}
            <div className={styles.statistics_RadioParent}>
              <RadioOption
                label="Home countries"
                value="homeCountries"
                selectedOption={selectedOption}
                onChange={handleOptionChange}
              />
              <RadioOption
                label="Destination countries"
                value="destinationCountries"
                selectedOption={selectedOption}
                onChange={handleOptionChange}
              />
            </div>
          </div>
          <div
            style={{ height: "15px" }}
            className="col-12 col-lg-6 d-flex justify-content-end"
          >
            <MapFilter />
          </div>
        </div>
      </div>

      <div className={styles.statisticsContainer}>
        <StaticsticsCard />

        <MapsAndGraphs
          homeCountrySelected={homeCountrySelected}
          hostCountrySelected={hostCountrySelected}
        />

        <div style={{ marginTop: "1rem" }}>
          <StaticsticsTable />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
