import React from "react";
import { SelectBox, MyDatePicker, TextInput } from "../../../components/common";

export const Workflow = () => (
  <section
    className="listing-section"
    style={{
      minHeight: "70vh",
    }}
  >
    <div className="container-fluid">
      <div className="col-md-12">
        <h2>Assign role to persona</h2>
        <div className="d-flex flex-column pt-4">
          <div
            className="row d-flex gap-4 gap-sm-0"
            style={{
              paddingBottom: "35px",
            }}
          >
            <div className="col-12 col-md-4">
              <SelectBox label="Main section" />
            </div>
            <div className="col-12 col-md-4">
              <TextInput label="Sub section" />
            </div>
            <div className="col-12 col-md-4">
              <TextInput label="Responsible person’s email" />
            </div>
          </div>
          <div
            className="row d-flex gap-4 gap-sm-0"
            style={{
              paddingBottom: "35px",
            }}
          >
            <div className="col-12 col-md-4">
              <MyDatePicker label="Date issued" />
            </div>
            <div className="col-12 col-md-4">
              <MyDatePicker label="Automated reminder 1" />
            </div>
            <div className="col-12 col-md-4">
              <MyDatePicker label="Automated reminder 2" />
            </div>
          </div>
          <div className="row d-flex gap-4 gap-sm-0">
            <div className="col-12 col-md-4">
              <SelectBox label="Manual reminder template" />
            </div>
            <div className="col-12 col-md-4">
              <SelectBox label="Escalation point" />
            </div>
          </div>
        </div>
        <div className="table-responsive pt-5">
          <table className="table">
            <thead>
              <tr>
                <th>Main Section</th>
                <th>Sub Section</th>
                <th>Responsible Person’s Email</th>
                <th>Date Issued</th>
                <th>Automated Reminder 1</th>
                <th>Automated Reminder 2</th>
                <th>Manual Reminder Template</th>
                <th>Escalation Point</th>
              </tr>
            </thead>
            <tbody>
              {[0, 1].map((i, index) => (
                <tr key={index}>
                  <td>Lorem</td>
                  <td>Lorem</td>
                  <td>Lorem</td>
                  <td>{index % 2 === 0 ? "24/01/2024" : "21/01/2024"}</td>
                  <td>Lorem</td>
                  <td>Lorem</td>
                  <td>Lorem</td>
                  <td>Lorem</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
);
