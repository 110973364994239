const introSelected = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1104_8704)">
        <g clipPath="url(#clip1_1104_8704)">
          <g clipPath="url(#clip2_1104_8704)">
            <path
              d="M18.8182 10.7273H12.4545C11.8759 10.7273 11.3209 10.4974 10.9118 10.0882C10.5026 9.67906 10.2727 9.12411 10.2727 8.54545V2.18182C10.2727 2.1336 10.2536 2.08735 10.2195 2.05325C10.1854 2.01916 10.1391 2 10.0909 2H5.90909C5.13755 2 4.39761 2.30649 3.85205 2.85205C3.30649 3.39761 3 4.13755 3 4.90909V19.4545C3 20.2261 3.30649 20.966 3.85205 21.5116C4.39761 22.0571 5.13755 22.3636 5.90909 22.3636H16.0909C16.8624 22.3636 17.6024 22.0571 18.1479 21.5116C18.6935 20.966 19 20.2261 19 19.4545V10.9091C19 10.8609 18.9808 10.8146 18.9467 10.7805C18.9126 10.7464 18.8664 10.7273 18.8182 10.7273ZM14.6364 18H7.36364C7.17075 18 6.98577 17.9234 6.84938 17.787C6.71299 17.6506 6.63636 17.4656 6.63636 17.2727C6.63636 17.0798 6.71299 16.8949 6.84938 16.7585C6.98577 16.6221 7.17075 16.5455 7.36364 16.5455H14.6364C14.8292 16.5455 15.0142 16.6221 15.1506 16.7585C15.287 16.8949 15.3636 17.0798 15.3636 17.2727C15.3636 17.4656 15.287 17.6506 15.1506 17.787C15.0142 17.9234 14.8292 18 14.6364 18ZM14.6364 14.3636H7.36364C7.17075 14.3636 6.98577 14.287 6.84938 14.1506C6.71299 14.0142 6.63636 13.8292 6.63636 13.6364C6.63636 13.4435 6.71299 13.2585 6.84938 13.1221C6.98577 12.9857 7.17075 12.9091 7.36364 12.9091H14.6364C14.8292 12.9091 15.0142 12.9857 15.1506 13.1221C15.287 13.2585 15.3636 13.4435 15.3636 13.6364C15.3636 13.8292 15.287 14.0142 15.1506 14.1506C15.0142 14.287 14.8292 14.3636 14.6364 14.3636Z"
              fill={process.env.REACT_APP_LIGHT_PRIMARY}
            />
            <path
              d="M18.4204 9.11888L11.8827 2.58113C11.87 2.56849 11.8538 2.55989 11.8362 2.55641C11.8186 2.55294 11.8004 2.55474 11.7838 2.56159C11.7673 2.56844 11.7531 2.58003 11.7431 2.59491C11.7331 2.6098 11.7277 2.6273 11.7277 2.64523V8.54652C11.7277 8.73944 11.8043 8.92445 11.9407 9.06086C12.0771 9.19727 12.2621 9.2739 12.455 9.2739H18.3563C18.3743 9.27382 18.3918 9.26845 18.4066 9.25846C18.4215 9.24846 18.4331 9.23429 18.44 9.21772C18.4468 9.20115 18.4486 9.18293 18.4451 9.16534C18.4417 9.14776 18.4331 9.13159 18.4204 9.11888V9.11888Z"
              fill={process.env.REACT_APP_LIGHT_PRIMARY}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1104_8704">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_1104_8704">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip2_1104_8704">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default introSelected;
