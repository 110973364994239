// Function to extract ordering field and order type from URL params
export const extractOrdering = (url) => {
  const params = new URL(url).searchParams;
  const ordering = params.get("ordering");

  if (!ordering) {
    return null;
  }

  const field = ordering.startsWith("-") ? ordering.slice(1) : ordering;
  const order = ordering.startsWith("-") ? "-" : "";

  return { field, order };
};
