import loadingPng from "../assets/images/spinner.png";

export const Loading = () => {
  return (
    <div className="position-fixed top-0 start-0 d-flex flex-column align-items-center justify-content-center w-100 h-100 overflow-hidden bg-black bg-opacity-50 loader-custom-class">
      <img src={loadingPng} className="w-4 h-4 spin-animation" alt="loading" />
      <div className="mt-3 text-start text-white ping-animation">Loading</div>
    </div>
  );
};
