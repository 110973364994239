import { forEach } from "lodash";
export function extractEmployerQuestions(Field) {
  const mapingField = Field;
  const result = [];
  forEach(mapingField.conditional_fields, (field) => {
    if (field.persona === "Employer" || field.persona === "Employer only") {
      if (field.conditional_fields?.length) {
        const resultingData = extractEmployerQuestions(field);
        if (resultingData.conditional_fields?.length) {
          result.push(field);
        }
      } else {
        result.push(field);
      }
    }
    if (field.persona === "Assumption" && field.conditional_fields?.length) {
      const resultingFields = extractEmployerQuestions(field);
      if (resultingFields.conditional_fields?.length) {
        result.push(resultingFields);
      }
    }
  });
  mapingField.conditional_fields = result;
  return mapingField;
}

export function filterEmployerQuestions(Field, employerDetail) {
  let result = [];
  if (Field.persona === "Employer" || Field.persona === "Employer only") {
    // if (Field.conditional_fields?.length) {
    //   forEach(Field.conditional_fields, (_item) => {
    //     const extractedEmployers = filterEmployerQuestions(
    //       _item,
    //       employerDetail
    //     );
    //     if (extractedEmployers.length) {
    //       result = [...result, ...extractedEmployers];
    //     }
    //   });
    // } else {
    //   result.push(Field);
    // }
    result.push(Field);
  }
  if (Field.persona === "Assumption" && Field.conditional_fields?.length) {
    forEach(Field.conditional_fields, (_item) => {
      if (_item.parent_value === employerDetail[Field.name]) {
        const extractedEmployers = filterEmployerQuestions(
          _item,
          employerDetail
        );
        if (extractedEmployers.length) {
          result = [...result, ...extractedEmployers];
        }
      }
    });
  }
  return result;
}

export function filterEMployerQuestionsHandler(Fields, employerDetail) {
  let result = [];
  forEach(Fields, (Field) => {
    const output = filterEmployerQuestions(Field, employerDetail);
    result = [...result, ...output];
  });
  return result;
}
