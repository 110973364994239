import React, { useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import sortIcon from "../../../assets/images/sort-icon.svg";
import {
  TextInput,
  NumberFormate,
  HelpText,
} from "../../../components/common/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getRemoteApplications } from "../../../slices/filledApplications.js";
import useCountryList from "../../../assets/shared/hooks/useCountryList.js";
import { TableLaoding } from "../../../components/common/Loading/TableLoading.js";
import { jwtDecode } from "jwt-decode";
import { CommentList } from "../../../components/common/Modal/commentList.jsx";
import styles from "./styles.module.css";
import { apiFetchWrapper } from "../../../utils/axiosApiWrapper.js";

const API_URL = process.env.REACT_APP_BACK_END_URL;

export const RemoteWork = () => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const dispatch = useDispatch();
  const { getCountryName } = useCountryList();
  const {
    remoteApplications,
    loading,
    remoteNextPageUrl,
    remotePreviousPageUrl,
    remoteCount,
    remotePage_size,
  } = useSelector((state) => state.filledApplications);

  const storageAccessToken = localStorage.getItem("AUTH0_TOKEN_RESPONSE") || "";
  const parsedData = storageAccessToken ? JSON.parse(storageAccessToken) : "";
  const userData = parsedData
    ? jwtDecode(parsedData.id_token.toString())
    : null;

  const [searchText, setSearchText] = React.useState("");
  const [searchTimeout, setSearchTimeout] = React.useState(null);

  const [sortCondition, setSortCondition] = React.useState({});
  const [pageNUmber, setPageNumber] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});
  const [allStatusList, setAllStatusList] = useState([]);

  const handleSorting = (field) => {
    const sortConditions = sortCondition;
    let order = "";
    if (sortConditions.field === field) {
      order = sortConditions.order === "" ? "-" : "";
    }
    setSortCondition({
      field,
      order,
    });

    let url =
      API_URL +
      `assessments/remote-work-assessments/?ordering=${order}${field}&page=${pageNUmber}`;
    getRemoteWorkHandler(url);
  };

  const filterContent = (search) => {};

  const getRemoteWorkHandler = React.useCallback(
    (url) => {
      dispatch(getRemoteApplications(url))
        .unwrap()
        .then(() => {
          filterContent();
        })
        .catch(() => {});
    },
    [dispatch]
  );

  const getStatusList = async () => {
    const url = API_URL + `assessments/remote-work-assessments/status-options/`;
    const res = await apiFetchWrapper.get(url, true);
    if (res.error) setAllStatusList([]);

    setAllStatusList(res?.data ? res?.data : []);
  };

  React.useEffect(() => {
    getRemoteWorkHandler();
    getStatusList();
  }, [getRemoteWorkHandler]);

  const fetchNextPage = () => {
    if (remoteNextPageUrl) {
      const nextPage = Number(pageNUmber) + 1;
      setPageNumber(nextPage);
      gotoSpecificPage(nextPage);
    }
  };

  const fetchPreviousPage = () => {
    if (remotePreviousPageUrl) {
      const previousPage = Number(pageNUmber) - 1;
      setPageNumber(previousPage);
      gotoSpecificPage(previousPage);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeout = setTimeout(() => {
      if (!value) {
        getRemoteWorkHandler();
      }

      if (value.length) {
        const url =
          API_URL + `assessments/remote-work-assessments/?search=${value}`;
        getRemoteWorkHandler(url);
      }
    }, 500); // Adjust the debounce time according to your needs

    setSearchTimeout(timeout);
  };

  const getFormattedDate = (date) => {
    const dateObj = new Date(date);
    // const timeOfDay = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
    const formattedDate = `${dateObj.getDate()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`;
    const formattedDateTime = `${formattedDate}`;

    return formattedDateTime;
  };

  const totalPages = Math.ceil(remoteCount / remotePage_size) || 0;

  const gotoSpecificPage = (page) => {
    const { field, order } = sortCondition;

    let url = API_URL + `assessments/remote-work-assessments/?page=${page}`;

    if (field) {
      url += `&ordering=${order}${field}`;
    }

    const urlObj = new URL(url);

    urlObj.searchParams.set("page", page);
    const pageUrl = urlObj.toString();
    getRemoteWorkHandler(pageUrl);
  };

  const debouncedGotoSpecificPage = useCallback(
    debounce((page) => gotoSpecificPage(page), 500),
    []
  );

  const statusHelpText = useMemo(() => {
    let text = "";
    allStatusList?.forEach((status) => {
      const detail = `${status.display_name}: ${status.description}`;
      text += `${text ? "\n\n" : ""} ${detail}`;
    });

    return text;
  }, [allStatusList]);

  return (
    <main>
      {loading ? (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
          style={{
            zIndex: "9999",
          }}
        >
          <TableLaoding />
        </div>
      ) : null}

      <section className="listing-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="d-flex gap-3">
              <h2>Employee Remote Work Request Dashboard</h2>
            </div>
            <p>
              This page allows you to review the status of remote work requests
              submitted by your employees. You can see key details for each
              request, including the status, home country, destination country,
              and reasons for approval or rejection.
            </p>

            <div className={`${styles.searchNcards_Container} mb-5 mb-lg-2`}>
              <div style={{ width: "250px" }}>
                <TextInput
                  label="Entity"
                  value={searchText}
                  isRequired={false}
                  placeHolder="Search here"
                  onChange={(name, value) => {
                    handleSearch(value);
                  }}
                  Icon={
                    <div className="pb-1">
                      <i className="h-100 bi bi-search" />
                    </div>
                  }
                />
              </div>
            </div>

            <div style={{ marginTop: "55px" }} className="row table-filter-div">
              <div className="col-12">
                <div>
                  <div className="table-responsive">
                    <div
                      className="overflow-auto"
                      style={{ maxHeight: "770px", minHeight: "fit-content" }}
                    >
                      <table
                        className="w-100"
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <div>ID</div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("first_name");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "85px" }}>
                                  First name
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("last_name");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "85px" }}>
                                  Last name
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("citizenship");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>
                                  Home country
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("employment_country");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>
                                  Destination
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("start_date");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>
                                  Start date
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("end_date");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>
                                  End date
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("status");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div>
                                  <HelpText
                                    contentDivClassNames="d-flex"
                                    noPadding={true}
                                    helpText={statusHelpText}
                                    // isIconeHide={true}
                                    content={
                                      <div
                                        style={{ paddingRight: "5px" }}
                                        className="d-flex align-items-center cursor-pointer"
                                      >
                                        Status
                                      </div>
                                    }
                                  />
                                </div>
                                <div></div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("status_reason");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>reason</div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <div style={{ minWidth: "110px" }}>Details</div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("created_at");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>
                                  Submitted
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {remoteApplications?.length
                            ? remoteApplications.map((i, index) => {
                                const statusText = allStatusList?.find(
                                  (status) => status.value === i.status
                                );

                                let statusColor =
                                  process.env.REACT_APP_LIGHT_PRIMARY;
                                if (statusText?.value === "REJECTED")
                                  statusColor = "#9F1D1D";
                                if (statusText?.value === "UNDER_REVIEW")
                                  statusColor = "#D09C00";
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {pageNUmber * remotePage_size -
                                          (remotePage_size - index) +
                                          1}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.first_name}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.last_name}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {getCountryName(i.citizenship)}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.employment_country
                                          ? getCountryName(i.employment_country)
                                          : ""}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {getFormattedDate(i.start_date)}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.end_date
                                          ? getFormattedDate(i.end_date)
                                          : "Indefinite"}
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <HelpText
                                          contentDivClassNames="d-flex"
                                          noPadding={true}
                                          helpText={
                                            statusText?.description || i.status
                                          }
                                          isIconeHide={true}
                                          content={
                                            <div
                                              style={{ color: statusColor }}
                                              className="cursor-pointer"
                                            >
                                              {statusText?.display_name ||
                                                i.status}
                                            </div>
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.status_reason}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <div
                                          style={{
                                            color:
                                              process.env
                                                .REACT_APP_LIGHT_PRIMARY,
                                          }}
                                          className="cursor-pointer text-decoration-underline"
                                        >
                                          {`[ Details ]`}
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {getFormattedDate(i.created_at)}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {!remoteApplications?.length ? (
                  <h5 className="w-full d-flex align-items-center justify-content-center py-5">
                    No data
                  </h5>
                ) : null}
              </div>
            </div>

            <div
              style={{ padding: "20px 10px", flexWrap: "wrap", gap: "5px" }}
              className="d-flex justify-content-between align-items-end"
            >
              <div>
                {remotePreviousPageUrl ? (
                  <button
                    style={{
                      backgroundColor: themeColor,
                      borderColor: themeColor,
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={fetchPreviousPage}
                    disabled={!remotePreviousPageUrl}
                  >
                    Previous page
                  </button>
                ) : null}
              </div>

              {!!remoteCount && (
                <div style={{ marginBottom: "7px" }}>
                  <div>
                    {" "}
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      Total remote applications:
                    </span>{" "}
                    <NumberFormate>{Number(remoteCount)}</NumberFormate>
                  </div>
                </div>
              )}

              {totalPages ? (
                <div style={{ marginBottom: "7px" }}>
                  <span style={{ fontWeight: "700" }}> Page </span>{" "}
                  <input
                    style={{
                      width: "80px",
                      height: "30px",
                      borderRadius: "20px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    type="number"
                    value={pageNUmber}
                    onChange={(e) => {
                      const value = Math.min(
                        totalPages,
                        Math.max(0, Number(e.target.value))
                      );
                      if (value < 1) return;
                      setPageNumber(value);
                      debouncedGotoSpecificPage(value);
                    }}
                    max={totalPages}
                    min={1}
                  />{" "}
                  of {totalPages}
                </div>
              ) : null}

              <div>
                {remoteNextPageUrl ? (
                  <button
                    style={{
                      backgroundColor: themeColor,
                      borderColor: themeColor,
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={fetchNextPage}
                    disabled={!remoteNextPageUrl}
                  >
                    Next page
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedItem?.uuid ? (
        <CommentList
          userData={userData}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />
      ) : null}
    </main>
  );
};
