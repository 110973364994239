import React from "react";
import sortIcon from "../../../assets/images/sort-icon.svg";
import { Icon as Iconify } from "@iconify/react";
import styles from "./styles.module.css";

export const Table = ({
  columns,
  data,
  pageSize,
  totalPages,
  inputPageNumber,
  onPageNumberChange,
  onPageSizeChange,
  previousPageUrl,
  nextPageUrl,
  onPreviousPage,
  onNextPage,
  onSort,
}) => {
  return (
    <div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  <div className="d-flex align-items-center gap-2">
                    {column.sortable && (
                      <img
                        onClick={() => onSort(column.key)}
                        src={sortIcon}
                        alt="sort-icon"
                        style={{ cursor: "pointer" }}
                      />
                    )}
                    <div>{column.label}</div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>
                    {column.render ? column.render(row) : row[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* ENTRIES */}
        <div className="d-flex align-items-center gap-2">
          <span>Show</span>
          <select
            className="form-select"
            style={{ width: "auto" }}
            value={pageSize}
            onChange={(e) => onPageSizeChange(Number(e.target.value))}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <span>entries</span>
        </div>

        {/* TOTAL PAGES */}
        {totalPages ? (
          <div
            style={{
              marginBottom: "7px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span style={{ fontWeight: "700" }}> Page </span>
            <input
              type="number"
              value={inputPageNumber}
              onChange={onPageNumberChange}
              style={{
                width: "60px",
                padding: "2px 5px",
                border: "1px solid #ddd",
                borderRadius: "4px",
              }}
              min="1"
              max={totalPages}
            />
            <span> of {totalPages}</span>
          </div>
        ) : null}

        {/* PAGINATION BTN */}
        <div className={styles.prevNextBtn_container}>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: previousPageUrl ? "pointer" : "not-allowed",
            }}
            type="button"
            onClick={onPreviousPage}
            disabled={!previousPageUrl}
          >
            <Iconify
              icon={"material-symbols:chevron-left"}
              width={30}
              color={previousPageUrl ? "#000" : "#bdbdbd"}
            />
          </button>

          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: nextPageUrl ? "pointer" : "not-allowed",
            }}
            type="button"
            onClick={onNextPage}
            disabled={!nextPageUrl}
          >
            <Iconify
              icon={"material-symbols:chevron-right"}
              width={30}
              color={nextPageUrl ? "#000" : "#bdbdbd"}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
