import { coordinatesList } from "./coordinatesList";

export const getCoordinates = async (country) => {
  const coordinate = coordinatesList.find((data) => data.key === country);

  if (coordinate) {
    return {
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
    };
  } else return null;
};
