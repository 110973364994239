import React, { useCallback, useEffect, useRef, useState } from "react";
import Close from "../../../assets/images/close.svg";
import { HelpText } from "../HelpText";
import { TitleText } from "../../TitleText";
import { isNil } from "lodash";

function formatDynamicPatternValue(
  value,
  countryName,
  regex,
  type,
  isDeleting
) {
  const dynamicPattern = regex
    .replace(/^\\d\{(\d+)\}$/, "($1)") // Extract digit groups
    .replace(/\\d\{(\d+)\}/g, (_, p1) => `(\\d{0,${p1}})`) // Convert digit segments to capturing groups with 0 as the minimum
    .replace(/-/g, ""); // Remove hyphens for pattern

  const is_hyphenCountry = countryName === "Portugal";
  const is_SlashValue = type === "uk_paye_reference_number";

  if (is_hyphenCountry) {
    const inputValue = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const is_hyphenCountry_Match = inputValue.match(new RegExp(dynamicPattern));
    if (is_hyphenCountry_Match) {
      value = is_hyphenCountry_Match.slice(1).filter(Boolean).join("-");
    }
  } else if (is_SlashValue) {
    const alphanumericValue = value.replace(/[^0-9A-Za-z]/g, "");

    if (isDeleting && alphanumericValue.length === 3) {
      // When backspacing at length 4 (3 chars + slash), remove 2 characters
      value = alphanumericValue.slice(0, -1);
    } else if (alphanumericValue.length >= 3) {
      value = alphanumericValue.slice(0, 3) + "/" + alphanumericValue.slice(3);
    }
  }

  return value;
}

export const TextInput = ({
  type = "string",
  description = "",
  isRequired = false,
  value = "",
  error = "",
  showError = false,
  helpText = "",
  name = "",
  maxLength,
  placeHolder,
  onChange = () => {},
  regex,
  countryName,
  autoPopulatedValues,
  setAutoPopulatedValues,
}) => {
  const [displayError, setDisplayError] = useState(false);
  const [missingField, setMissingField] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setIsDeleting(true);
    } else {
      setIsDeleting(false);
    }
  };

  const inputRef = useRef(null);
  let inputType = "text";

  switch (type) {
    case "integer": {
      inputType = "number";
      break;
    }
    case "password": {
      inputType = "password";
      break;
    }
    default:
      inputType = "text";
  }

  const handleChange = useCallback(
    (event) => {
      let value = event.target.value;

      if (regex)
        value = formatDynamicPatternValue(
          value,
          countryName,
          regex,
          type,
          isDeleting
        );

      onChange(name, value);
      if (autoPopulatedValues?.[name]) {
        setAutoPopulatedValues((prev) => {
          const { [name]: _, ...rest } = prev;
          return rest;
        });
      }
    },
    [name, onChange, isDeleting]
  );
  useEffect(() => {
    if (error && showError) setDisplayError(true);
    else setDisplayError(false);
  }, [error, showError]);

  useEffect(() => {
    if (name && !value && isRequired) {
      setMissingField(true);
    } else {
      setMissingField(false);
    }
  }, [value, isRequired, name]);

  return (
    <>
      <div
        className="w-100 h-100 d-flex flex-column gap-1 justify-content-between"
        style={{
          borderBottom: `2px solid ${
            error && displayError ? "#a51e22" : "#CECECE"
          }`,
        }}
      >
        <div className="d-flex align-items-center mb-2">
          <label htmlFor={name} className="d-flex">
            <TitleText
              style={
                (error && displayError) || missingField
                  ? { color: "#a51e22" }
                  : {}
              }
              children={`${description}${
                !isRequired && description ? " (optional)" : ""
              }`}
            />
          </label>
          {helpText && <HelpText helpText={helpText} />}
        </div>
        <div className="d-flex w-100 align-items-center align-content-center justify-content-center gap-2">
          <input
            style={{
              lineHeight: "18px",
              fontWeight: "500",
              fontSize: "15px",
            }}
            id={name}
            onKeyDown={handleKeyDown}
            name={name}
            type={inputType}
            value={value}
            maxLength={maxLength}
            ref={inputRef}
            className={`text-input placeholder-style value-style ${
              autoPopulatedValues && autoPopulatedValues?.[name] === value
                ? "prefilled-color"
                : "not-prefilled-color"
            }`}
            placeholder={placeHolder}
            onChange={(event) => handleChange(event)}
          />
          {(type === "percentage" || type === "work_percentage") && (
            <span>%</span>
          )}
          {value !== "" && !isNil(value) ? (
            <img
              className="bi-close cursor-pointer"
              src={Close}
              alt="close"
              onClick={() => onChange(name, "")}
            />
          ) : null}
        </div>
      </div>
      {error && displayError && (
        <div style={{ color: "#a51e22", fontSize: "0.8rem" }}>{error}</div>
      )}
    </>
  );
};
