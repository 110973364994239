import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumberFormate } from "../../../../components/common";
import { debounce, uniqueId } from "lodash";
import {
  downloadFilledApplications,
  getFilledApplications,
} from "../../../../slices/filledApplications";
import FilledApplicationTable from "../../../../components/common/filedAplicationTable";
import * as FileSaver from "file-saver";
import { useAnalyticsFilter } from "../analyticsContext";
import getQueryParamsFromObj from "../../../../utils/getParamsFromObj";

const StaticsticsTable = () => {
  const [pageNUmber, setPageNumber] = useState(1);
  const { filterData } = useAnalyticsFilter();
  const [isDowndablePDF, setIsDowndablePDF] = useState(false);
  const [downLoadBtnLoading, setDownLoadBtnLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const API_URL = process.env.REACT_APP_BACK_END_URL;
  const dispatch = useDispatch();
  const {
    nextPageUrl,
    previousPageUrl,
    count,
    page_size,
    selectedRoute,
    filledApplications,
  } = useSelector((state) => state.filledApplications);

  const baseStyle = {
    height: "45px",
    color: themeColor,
    borderColor: themeColor,
    backgroundColor: "white",
  };

  const disabledStyle = {
    cursor: "not-allowed",
    pointerEvents: "all",
  };

  const combinedStyle = !isDowndablePDF
    ? { ...baseStyle, ...disabledStyle }
    : baseStyle;

  const totalPages = Math.ceil(count / page_size) || 0;

  const getFilledApplicationsHandler = useCallback(
    (url) => {
      dispatch(getFilledApplications(url))
        .unwrap()
        .then(() => {})
        .catch(() => {});
    },
    [dispatch]
  );
  const gotoSpecificPage = (page) => {
    const advanceFilterQ = getQueryParamsFromObj(filterData);

    const url =
      API_URL +
      `compliance/compliance-requests/${advanceFilterQ}&page=2&${
        searchQuery ? searchQuery : ""
      }`;
    const urlObj = new URL(url);

    urlObj.searchParams.set("page", page);
    const pageUrl = urlObj.toString();
    getFilledApplicationsHandler(pageUrl);
  };

  const fetchPreviousPage = (page) => {
    if (previousPageUrl) {
      const previousPage = Number(pageNUmber) - 1;
      setPageNumber(previousPage);
      gotoSpecificPage(previousPage);
    }
  };
  const fetchNextPage = () => {
    if (nextPageUrl) {
      const nextPage = Number(pageNUmber) + 1;
      setPageNumber(nextPage);
      gotoSpecificPage(nextPage);
    }
  };
  const debouncedGotoSpecificPage = useCallback(
    debounce((page) => gotoSpecificPage(page), 500),
    []
  );
  const csvDownloadHandler = () => {
    setDownLoadBtnLoading(true);
    const url = API_URL + `compliance/compliance-requests/export/`;

    dispatch(
      downloadFilledApplications(
        `${url}${searchQuery ? "?" + searchQuery : ""}`
      )
    )
      .unwrap()
      .then((response) => {
        setDownLoadBtnLoading(false);
        // Create a blob from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Use the FileSaver library to save the file
        FileSaver.saveAs(blob, `Filed_applications_${uniqueId()}`);
      })
      .catch((err) => {
        setDownLoadBtnLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    let isDownloadStatus = false;
    filledApplications?.forEach((i) => {
      const downloadFilesUrls = i?.reference_details?.files;
      if (downloadFilesUrls?.length) isDownloadStatus = true;
    });
    setIsDowndablePDF(isDownloadStatus);
  }, [filledApplications]);

  const setSearchQuesryHandler = useCallback(() => {
    let searchquery = "";
    if (selectedRoute && selectedRoute.home_country)
      searchquery =
        searchquery +
        `${searchquery ? "&" : ""}home_country=${selectedRoute.home_country}`;
    if (selectedRoute && selectedRoute.host_country)
      searchquery =
        searchquery +
        `${searchquery ? "&" : ""}host_country=${selectedRoute.host_country}`;
    setSearchQuery(searchquery);
  }, [selectedRoute]);

  useEffect(() => {
    setSearchQuesryHandler();
  }, [selectedRoute, setSearchQuesryHandler]);

  useEffect(() => {
    if (previousPageUrl === null) setPageNumber(1);
  }, [previousPageUrl]);

  return (
    <div className="table-filter-div">
      <FilledApplicationTable pageNUmber={pageNUmber} />
      <div
        style={{ padding: "20px 10px", flexWrap: "wrap", gap: "5px" }}
        className="d-flex justify-content-between align-items-end"
      >
        <div>
          {previousPageUrl ? (
            <button
              style={{
                backgroundColor: themeColor,
                borderColor: themeColor,
              }}
              type="button"
              className="btn btn-danger"
              onClick={fetchPreviousPage}
              disabled={!previousPageUrl}
            >
              Previous page
            </button>
          ) : null}
        </div>

        {!!count && (
          <div style={{ marginBottom: "7px" }}>
            <div>
              {" "}
              <span style={{ fontWeight: "700" }}>
                {" "}
                Total filed applications:
              </span>{" "}
              <NumberFormate>{Number(count)}</NumberFormate>
            </div>
          </div>
        )}

        {totalPages ? (
          <div style={{ marginBottom: "7px" }}>
            <span style={{ fontWeight: "700" }}> Page </span>{" "}
            <input
              style={{
                width: "80px",
                height: "30px",
                borderRadius: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              type="number"
              value={pageNUmber}
              onChange={(e) => {
                const value = Math.min(
                  totalPages,
                  Math.max(0, Number(e.target.value))
                );
                if (value < 1) return;
                setPageNumber(value);
                debouncedGotoSpecificPage(value);
              }}
              max={totalPages}
              min={1}
            />{" "}
            of {totalPages}
          </div>
        ) : null}
        {totalPages ? (
          <button
            style={combinedStyle}
            type="button"
            className="btn  px-8 "
            disabled={downLoadBtnLoading}
            onClick={(e) => {
              csvDownloadHandler();
            }}
          >
            {downLoadBtnLoading ? (
              <span className="spinner-border spinner-border-sm me-1"></span>
            ) : (
              "Export to Excel"
            )}
          </button>
        ) : null}

        <div>
          {nextPageUrl && pageNUmber < totalPages ? (
            <button
              style={{
                backgroundColor: themeColor,
                borderColor: themeColor,
              }}
              type="button"
              className="btn btn-danger"
              onClick={fetchNextPage}
              disabled={!nextPageUrl}
            >
              Next page
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StaticsticsTable;
