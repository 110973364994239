import { fileContentType } from "../common/constants";

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64String = event?.target?.result;
      if (base64String) resolve(base64String);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}
export function base64ToFile(base64String, fileName) {
  const byteString = atob(base64String?.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: "application/octet-stream" });
  return new File([blob], fileName, { type: "application/octet-stream" });
}
export function isBase64(str) {
  const base64Regex = /^[A-Za-z0-9+/]*={0,2}$/;
  if (str?.indexOf(",") !== -1) {
    const base64Data = str?.split(",")[1];
    if (!base64Data) return false;
    return base64Regex.test(base64Data);
  }
  return base64Regex.test(str);
}

export function getFileContentType(fileName) {
  const splitFile = fileName.split(".");
  const fileExstenstion = splitFile[splitFile.length - 1];
  return fileContentType[fileExstenstion] || "application/octet-stream";
}
export function stringToBase64(str) {
  return btoa(str);
}
export const embedBaseUrlIntoUrl = (url) => {
  const API_URL = process.env.REACT_APP_BACK_END_URL;
  // Find the start index of "/api/" in the original URL
  const apiIndex = url.indexOf("/api/");

  // Extract the part of the URL after "/api/"
  const pathAfterApi = url.slice(apiIndex + 5); // Skip "/api/"

  // Combine the new URL part with the path after "/api/"
  const newUrl = API_URL + pathAfterApi;

  return newUrl;
};
