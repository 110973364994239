import React from "react";
import Card from "./card";
import {
  CardBackgroundColors,
  CountryColors,
} from "../../../../common/constants";
import { useSelector } from "react-redux";
const StaticsticsCard = () => {
  const {
    applicationCompletedCount,
    applicationCountriesCoverdCount,
    a1Count,
    pwnCount,
    processing_days,
  } = useSelector((state) => state.filledApplications);
  const cardsData = [
    {
      title: "A1s",
      data: a1Count.toLocaleString(),
    },
    {
      title: "Home countries covered",
      data: applicationCountriesCoverdCount.toLocaleString(),
    },
    {
      title: "Average days for approval",
      data: processing_days.toLocaleString(),
    },
    // {
    //   title: a1Count,
    //   content: "A1 filed",
    // },
    // {
    //   title: pwnCount,
    //   content: "PWN filed",
    // },
  ];
  return (
    <div
      style={{
        gap: "15px",
        paddingBottom: 25,
      }}
      className="d-flex my-2"
    >
      {cardsData?.map((_item, index) => {
        return (
          <Card
            key={index}
            data={_item.data}
            title={_item.title}
            backgroundColor={CardBackgroundColors[`CARD${index + 1}`]}
            color={CountryColors[`COUNTRY${index + 1}`]}
          />
        );
      })}
    </div>
  );
};

export default StaticsticsCard;
