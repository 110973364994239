import React from "react";
import { MyModal } from "../../../../components/common";
import { Loading } from "../../../../components/Loading";
import { API_URL, primaryColor } from "../../../../common/env";
import { deletePersonaEntities } from "../../../../slices/dashboard";
import { useDispatch } from "react-redux";

const DeleteModal = ({
  getPersonaEntitiesHandler,
  deleteEntity,
  setDeleteEntity,
  saveLoading,
}) => {
  const dispatch = useDispatch();

  const deletePersonaEntitiesHandler = (url) => {
    setDeleteEntity("");
    dispatch(deletePersonaEntities(url))
      .unwrap()
      .then(() => {
        getPersonaEntitiesHandler();
      })
      .catch(() => {});
  };
  return (
    <MyModal>
      {saveLoading && <Loading />}
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-center flex-wrap">
          <h2>Confirmation</h2>
        </div>

        <div className="d-flex align-items-center justify-content-center flex-wrap my-5">
          Are you sure you want to delete this item? This action is permanent
          and cannot be undone.
        </div>

        <div className="row w-100">
          <div className="col">
            <div className="d-flex w-100 justify-content-center gap-3">
              <button
                style={{
                  color: primaryColor,
                  borderColor: primaryColor,
                  backgroundColor: "white",
                }}
                type="button"
                className="btn btn-danger"
                onClick={() => setDeleteEntity("")}
              >
                Cancel
              </button>
              <button
                style={{
                  backgroundColor: primaryColor,
                  borderColor: primaryColor,
                }}
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  const url = API_URL + `personas/entities/${deleteEntity}/`;
                  deletePersonaEntitiesHandler(url);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </MyModal>
  );
};

export default DeleteModal;
