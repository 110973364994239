import { TitleText } from "../../../components/TitleText";

export const AccordionItem = (props) => {
  const { header, type, faqType, handleFaq } = props;

  return (
    <button
      style={{ height: "95px", border:'0px',backgroundColor: "#e5e7eb" }}
      className={`d-flex align-items-center  justify-content-between w-100 min-h-20 px-4 py-3`}
      onClick={handleFaq}
    >
      <TitleText restStyle="w-full text-left lg:text-lg sm:text-[18px] max-sm:text-[16px]">
        {header}
      </TitleText>
      {type === faqType ? <TitleText>-</TitleText> : <TitleText>+</TitleText>}
    </button>
  );
};
