import React from "react";
import BarGrapgh from "./grapghs/barGrapgh";
import LineGrapgh from "./grapghs/lineGraph";
import MapWithPins from "./Map";
import styles from "./styles.module.css";
import DoubleBarGraph from "./grapghs/doubleBarGraph";

const CountryTypeLabel = ({ label, primaryColor }) => (
  <div className="d-flex mt-2 mx-2" style={{ alignItems: "center" }}>
    <p style={{ margin: 0, fontSize: "0.85rem", lineHeight: "normal" }}>
      {label}
    </p>
    <div
      style={{
        width: "17px",
        height: "17px",
        backgroundColor: primaryColor,
        borderRadius: "50%",
        marginLeft: 5,
      }}
    />
  </div>
);

const MapsAndGraphs = ({ homeCountrySelected, hostCountrySelected }) => {
  return (
    <div className={styles.analyticsCards_Container}>
      <div className={styles.analyticsCards_secondSection}>
        <DoubleBarGraph
          homeCountrySelected={homeCountrySelected}
          hostCountrySelected={hostCountrySelected}
        />
      </div>
      <div className={styles.analyticsCards_firstSection}>
        <div className={`p-2`}>
          <div style={{ flexWrap: "wrap" }} className="d-flex mb-2">
            <CountryTypeLabel
              label="Home countries"
              primaryColor={process.env.REACT_APP_LIGHT_PRIMARY}
            />
            <CountryTypeLabel
              label="Destination countries"
              primaryColor="#1F3543"
            />
            <CountryTypeLabel
              label="Selected country"
              primaryColor="rgb(255, 115, 72)"
            />
          </div>
          <MapWithPins
            homeCountrySelected={homeCountrySelected}
            hostCountrySelected={hostCountrySelected}
          />
        </div>

        <LineGrapgh
          homeCountrySelected={homeCountrySelected}
          hostCountrySelected={hostCountrySelected}
        />
      </div>

      <div className={styles.analyticsCards_secondSection}>
        <BarGrapgh
          homeCountrySelected={homeCountrySelected}
          hostCountrySelected={hostCountrySelected}
        />
      </div>
    </div>
  );
};

export default MapsAndGraphs;
