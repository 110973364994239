import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import Icon, { ICON_NAMES } from "../../Icone";
import { apiFetchWrapper } from "../../../utils/axiosApiWrapper";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { AddComment } from "./addCommentModal";
import {
  deleteApplicationsComment,
  getApplicationsComments,
  getFilledApplications,
} from "../../../slices/filledApplications";
import { TableLaoding } from "../Loading/TableLoading";
import { MyModal } from ".";
import { Loading } from "../../Loading";

export const CommentList = ({ selectedItem, setSelectedItem, userData }) => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [comments, setComments] = useState([]);
  const [addCommentModal, setAddCommentModal] = useState(false);
  const [deleteCommentModal, setDeleteCommentModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState({});
  const [nextPage, setNextPage] = useState("");
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const getAllComments = useCallback(
    async (search) => {
      try {
        if (search) setLoadingMore(true);
        else setLoading(true);
        dispatch(getApplicationsComments({ uuid: selectedItem.uuid, search }))
          .unwrap()
          .then((res) => {
            setLoading(false);
            setLoadingMore(false);
            setNextPage(res.next);
            if (search) {
              setComments((prevComments) => [...prevComments, ...res.results]);
            } else {
              setComments([...res.results]);
            }
          })
          .catch(() => {
            setLoading(false);
            setLoadingMore(false);
          });
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [selectedItem, dispatch]
  );

  useEffect(() => {
    getAllComments();
  }, [getAllComments]);

  const deleteCommentHandler = () => {
    setDeleteLoading(true);
    dispatch(
      deleteApplicationsComment({
        commentUuid: selectedComment.uuid,
        applicationUuid: selectedItem.uuid,
      })
    )
      .unwrap()
      .then((res) => {
        setDeleteLoading(false);
        setSelectedComment({});
        setDeleteCommentModal(false);
        getAllComments();
        dispatch(getFilledApplications());
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  };

  const Comment = ({ user_full_name, created_at, comment, email, uuid }) => {
    return (
      <div className="comment-container">
        <div className="comment-header">
          <span className="comment-user">{user_full_name}</span>
          <span className="comment-date">
            {dayjs(created_at).format("M/D/YYYY")}
          </span>
        </div>
        <div className="comment-body">
          <div>
            <div
              style={{ backgroundColor: themeColor }}
              className="comment-icon"
            >
              {user_full_name
                .split(" ")
                .map((name) => name[0])
                .join("")}
            </div>
          </div>

          <div
            style={
              userData?.email === email
                ? {
                    backgroundColor: "#f2d7d7",
                  }
                : {}
            }
            className="comment-text"
          >
            <div className="d-flex justify-content-between">
              {comment?.replace(/<at>([^<]+)<\/at>/g, "@$1")}
              {userData?.email === email ? (
                <div style={{ marginTop: "-10px" }} className="d-flex">
                  <div
                    onClick={() => {
                      setSelectedComment({ uuid, comment });
                      setAddCommentModal(true);
                    }}
                    className="cursor-pointer"
                  >
                    <Icon width={14} height={14} name={ICON_NAMES.EDIT} />
                  </div>
                  <div
                    onClick={() => {
                      setSelectedComment({ uuid, comment });
                      setDeleteCommentModal(true);
                    }}
                    className="cursor-pointer"
                  >
                    <Icon width={12} height={12} name={ICON_NAMES.DELETICONE} />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="comment-time">
              {dayjs(created_at).format("h:mm A")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loadMoreHandler = () => {
    const url = nextPage;
    const urlObj = new URL(url);
    const searchParams = new URLSearchParams(urlObj.search);
    const page = searchParams.get("page");
    const search = `?page=${page}`;
    getAllComments(search);
  };

  return (
    <div className={styles.modalParent}>
      <div
        ref={modalRef}
        className={`shadow bg-white ${styles.modalBody} p-2  pb-5`}
      >
        <div className="w-100">
          {loading ? (
            <div
              className=" top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center  bg-opacity-50"
              style={{
                zIndex: "9999",
              }}
            >
              <TableLaoding />
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-end">
                <div
                  onClick={() => setSelectedItem({})}
                  className="cursor-pointer"
                >
                  <Icon name={ICON_NAMES.CROSSICONE} />
                </div>
              </div>
              <div className="px-3">
                <div id="showInstructionMainDIv" className="w-100">
                  <h2> All Comments</h2>

                  <>
                    <div className="comments-list">
                      {comments.length ? (
                        comments?.map((comment) => (
                          <Comment
                            key={comment.uuid}
                            uuid={comment.uuid}
                            user_full_name={comment.user_full_name}
                            created_at={comment.created_at}
                            comment={comment.comment}
                            email={comment.user_email}
                          />
                        ))
                      ) : (
                        <div className="d-flex w-full justify-content-center mt-3">
                          Currently no comment to show
                        </div>
                      )}
                      {nextPage ? (
                        <div className="d-flex w-full justify-content-center">
                          {loadingMore ? (
                            <>
                              Loading{" "}
                              <span className="spinner-border spinner-border-sm me-1 mt-1"></span>
                            </>
                          ) : (
                            <div
                              onClick={loadMoreHandler}
                              className="load-more-btn"
                            >
                              {" "}
                              Load more
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </>
                </div>
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex mt-5 gap-2 justify-content-center">
                    <button
                      style={{
                        color: themeColor,
                        borderColor: themeColor,
                        backgroundColor: "white",
                        width: "150px",
                      }}
                      type="button"
                      className="btn"
                      onClick={() => setSelectedItem({})}
                    >
                      Close
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        borderColor: themeColor,
                      }}
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        setAddCommentModal(true);
                      }}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {addCommentModal ? (
        <AddComment
          setSelectedItem={setSelectedComment}
          selectedItem={selectedComment}
          setAddCommentModal={setAddCommentModal}
          getAllComments={getAllComments}
          applicationUuid={selectedItem.uuid}
        />
      ) : null}
      {deleteCommentModal && selectedComment && (
        <MyModal>
          {loading && <Loading />}
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              <h2>Confirmation</h2>
            </div>

            <div className="d-flex align-items-center justify-content-center flex-wrap my-5">
              Are you sure you want to delete this item? This action is
              permanent and cannot be undone.
            </div>

            <div className="row w-100">
              <div className="col">
                <div className="d-flex w-100 justify-content-center gap-3">
                  <button
                    style={{
                      color: themeColor,
                      borderColor: themeColor,
                      backgroundColor: "white",
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      setDeleteCommentModal(false);
                      setSelectedComment({});
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      backgroundColor: themeColor,
                      borderColor: themeColor,
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => deleteCommentHandler()}
                    disabled={deleteLoading}
                  >
                    {deleteLoading && (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    )}
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MyModal>
      )}
    </div>
  );
};
