import React, { useEffect } from "react";
import styles from "./index.module.css";

export const MyModal = ({ children, style = {}, onClose = () => {} }) => {
  useEffect(() => {
    document.body.classList.add("body-overflowHidden");

    return () => {
      document.body.classList.remove("body-overflowHidden");
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className={styles.modalParent}>
      <div
        className={`shadow bg-white ${styles.modalBody}`}
        style={{
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
};
