import { isEqual, size } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationBar } from "./NavigationBar";
import {
  updateValues,
  changeDisableNextStepStatus,
  submitFormFieldData,
  updateSelectedGroup,
  updateShowError,
} from "../../../../slices/questionnaire";
import { useNavigate } from "react-router-dom";
import { closeAll, onExpandAndClose } from "../../../../slices/layout";
import { updatePersonaEntities } from "../../../../slices/dashboard";
import { MyModal } from "../../../../components/common";
import { primaryColor } from "../../../../common/env";
import { useQuestionnaire } from "../context";

function removeDuplicatesByKey(arr) {
  const seenKeys = new Set(); // To track seen keys
  return arr.filter((item) => {
    if (seenKeys.has(item.key)) {
      return false; // If key already seen, filter it out
    } else {
      seenKeys.add(item.key); // Add key to set
      return true; // Keep this item
    }
  });
}

export const NavigationWrapper = (props) => {
  const {
    values,
    stepsGroupObj,
    currentSelectedGroup,
    item,
    personaComplianceValues,
    validateForm,
    fieldResetFlagRef,
  } = props;
  const {
    flatenFilterdEmployerA1Questionaire,
    flatenFilterdEmployerMSWQuestionaire,
    flatenFilterdEmployerCOCQuestionaire,
    flatedFields,
    questionnaire,
  } = useSelector((state) => state.questionnaire);
  const { routes } = useSelector((state) => state.layout);
  const { autoPopulatedValues } = useQuestionnaire();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLastGroup = isEqual(currentSelectedGroup, size(stepsGroupObj));
  const isFirstGroup = isEqual(currentSelectedGroup, 1);

  const [showIncompleteInfoModal, setShowIncompleteInfoModal] = useState(false);
  const resolveDecisionRef = useRef(null); // Store resolve function here

  // Deferred Promise Function
  const waitForUserSubmitDecision = () => {
    return new Promise((resolve) => {
      resolveDecisionRef.current = resolve; // Store the resolve function
    });
  };

  const getFieldsToValidate = useMemo(() => {
    const fieldKeysToExtract = flatedFields
      .filter(
        (_field) => _field.group.trim() === stepsGroupObj[currentSelectedGroup]
      )
      .map((_field) => _field.name);

    const fieldsToValidate = Object.keys(values).reduce((acc, key) => {
      if (fieldKeysToExtract.includes(key)) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    return fieldsToValidate;
  }, [values, stepsGroupObj, currentSelectedGroup, flatedFields]);

  const goToNextGroup = () => {
    if (!isLastGroup) {
      dispatch(updateSelectedGroup({ group: currentSelectedGroup + 1 }));
      fieldResetFlagRef.current = false;
    }
  };

  const isErrorInField = async () => {
    let errorExist = false;

    const _errors = await validateForm(getFieldsToValidate);

    for (const field of flatedFields) {
      if (_errors[field.name]) {
        errorExist = true;
        break;
      }
    }

    return errorExist;
  };

  useEffect(() => {
    // RUNs VALIDATION EACH TIME STEP IS CHANGED, TO SHOW VALIDATION ERRORS FOR AUTO-POPULATED FIELDS
    (async () => {
      try {
        await validateForm();
      } catch (error) {
        console.error("VALIDATE FORM", error);
      }
    })();
  }, [validateForm, currentSelectedGroup]);

  const goToPrevGroup = async () => {
    if (!isFirstGroup) {
      let errorExist = await isErrorInField();
      if (errorExist) return;
      dispatch(updateSelectedGroup({ group: currentSelectedGroup - 1 }));
      fieldResetFlagRef.current = false;
    }
  };

  const updateSidebar = (pathname) => {
    dispatch(closeAll({}));
    const route =
      routes.find((i) => i.link === pathname) ||
      routes.find((i) => i.children.find((j) => j.link === pathname));
    if (route) {
      dispatch(onExpandAndClose({ id: route.id }));
    }
  };
  const onBack = () => {
    updateSidebar("/complete-questionnaire");
    navigate("/complete-questionnaire");
  };

  const determinedBackFunc = () => {
    isFirstGroup ? onBack() : goToPrevGroup();
  };

  // CHECKS IF THE FIELD ARRAY HAS ALL THE VALUES FILLED IN
  const isFieldComplete = (arr) => {
    if (arr.length === 0) {
      return true;
    }

    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].value === "" ||
        arr[i].value === null ||
        arr[i].value === undefined
      ) {
        return false;
      }
    }

    return true;
  };

  // Extracting conditional fields details into new array to be used to remove unneeded field names in final form submit payload.
  const conditionalFields = useMemo(() => {
    const result = [];

    // Recursive helper function to add conditional fields
    const addConditionalFields = (fields, parentFieldName = null) => {
      fields.forEach((field) => {
        // If the current field has conditional fields, process them
        if (
          field?.conditional_fields &&
          Array.isArray(field.conditional_fields)
        ) {
          field.conditional_fields.forEach((conditionalField) => {
            // Add the conditional field details to the result array
            result.push({
              parent_field_name: parentFieldName || field.name,
              parent_field_value: conditionalField.parent_value,
              conditional_field_name: conditionalField.name,
            });

            // Recursively handle any further nested conditional fields
            addConditionalFields([conditionalField], conditionalField.name);
          });
        }
      });
    };

    // Start processing fields from the top-level questionnaire
    if (Array.isArray(questionnaire)) {
      // Only pick fields that have conditional_fields
      const fieldsWithConditionalFields = questionnaire.filter(
        (field) =>
          field.conditional_fields && Array.isArray(field.conditional_fields)
      );
      addConditionalFields(fieldsWithConditionalFields);
    }

    return result;
  }, [questionnaire]);

  // We want all normal fields that are not required.
  // Filter questionnaire for only required fields, then

  // Filters final payload fields using the conditionalFields array
  function removeRedundantCondtionalFields(_fields) {
    return _fields.filter((_field) => {
      const matchingConditionalField = conditionalFields.find(
        (conditionalField) =>
          conditionalField.conditional_field_name === _field.key
      );

      if (!matchingConditionalField) {
        return true; // Keep if no matching conditional field is found
      }
      const { parent_field_name, parent_field_value } =
        matchingConditionalField;

      return values?.[parent_field_name] === parent_field_value;
    });
  }

  const determinedNextFunc = async () => {
    dispatch(updateShowError({ flag: true }));
    let errorExist = await isErrorInField();
    let updateCompanyName = "";
    if (errorExist) return;
    questionnaire.forEach((field) => {
      const value = values[field.name]?.toString();
      if (value && field.type === "employer_name") updateCompanyName = value;
    });

    dispatch(updateValues({ values }));
    if (isLastGroup) {
      /**
       * Handles the creation and submission of questionnaire payloads.
       * Maps compliance types to their specific questionnaires and field values,
       * generates fields based on provided values, removes redundancy, and ensures
       * completeness. Dispatches the payload and navigates based on user decisions.
       */

      // Mappings for different compliance types
      const questionnaireMapping = {
        A1: flatenFilterdEmployerA1Questionaire,
        "MSW-A1": flatenFilterdEmployerMSWQuestionaire,
        COC: flatenFilterdEmployerCOCQuestionaire,
      };
      // Optimized field value mapping using reduce to avoid repetitive find calls
      const fieldValueMapping = personaComplianceValues.reduce((acc, _val) => {
        if (["A1", "MSW-A1", "COC"].includes(_val.compliance_type)) {
          acc[_val.compliance_type] = _val.fields;
        }
        return acc;
      }, {});
      // Get payload for a specific compliance type
      const getPayload = (type) =>
        personaComplianceValues.find((_item) => _item.compliance_type === type);
      let a1Fields = [];
      let mswFields = [];
      let cocFields = [];
      // Create fields for a specific compliance type
      const createPayloadFields = (type) => {
        const _questionnaire = questionnaireMapping[type];
        const fieldValues = fieldValueMapping[type] || [];

        const fields = [];
        // Iterate over values to determine fields to include
        Object.keys(values).forEach((_item) => {
          let autoPopulated = false;
          if (autoPopulatedValues[_item]) {
            const field = fieldValues.find((_itm) => _itm.key === _item);
            if (field && field.value === values[_item]) {
              autoPopulated = true;
            }
          }

          const existsInQuestionnaire = _questionnaire.find(
            (__item) => __item.name === _item
          );
          if (existsInQuestionnaire) {
            fields.push({
              key: _item,
              value: values[_item],
              auto_populated: autoPopulated,
            });
            // Handle employer_common_name logic to propagate values to other compliance types
            if (existsInQuestionnaire.employer_common_name) {
              // Check and add to MSW fields if applicable
              if (type === "A1") {
                const commonNameExistsInMSW = questionnaireMapping[
                  "MSW-A1"
                ].find(
                  (__item) =>
                    __item.employer_common_name ===
                    existsInQuestionnaire.employer_common_name
                );
                if (commonNameExistsInMSW) {
                  mswFields.push({
                    key: commonNameExistsInMSW.name,
                    value: values[_item],
                    auto_populated: autoPopulated,
                  });
                }
              }

              // Check and add to COC fields if applicable
              if (type !== "COC") {
                const commonNameExistsInCOC = questionnaireMapping["COC"].find(
                  (__item) =>
                    __item.employer_common_name ===
                    existsInQuestionnaire.employer_common_name
                );
                if (commonNameExistsInCOC) {
                  cocFields.push({
                    key: commonNameExistsInCOC.name,
                    value: values[_item],
                    auto_populated: autoPopulated,
                  });
                }
              }
            }
          }
        });
        return fields;
      };
      // Remove redundant and duplicate fields
      const removeRedundancyAndDuplicates = (fields) => {
        return removeDuplicatesByKey(removeRedundantCondtionalFields(fields));
      };
      // Create the final payload for a specific compliance type
      const createFinalPayload = (payloadType, fields) => {
        const payload = getPayload(payloadType);
        const finalFields = removeRedundancyAndDuplicates(fields);
        const filteredFields = finalFields.filter((finalField) => {
          const matchingField = flatedFields.find(
            (field) => field.name === finalField.key
          );
          // Exclude if 'required' is undefined or false
          if (!matchingField || matchingField.required === undefined) {
            return false;
          }
          // Include if 'required' is true OR 'value' is not empty
          return (
            matchingField?.required === true ||
            String(finalField.value).length > 0
          );
        });
        const isComplete = isFieldComplete(filteredFields);

        return payload
          ? {
              uuid: payload.uuid,
              is_complete: isComplete,
              fields: finalFields,
            }
          : null;
      };
      // Create fields for each compliance type
      a1Fields = [...a1Fields, ...createPayloadFields("A1")];
      mswFields = [...mswFields, ...createPayloadFields("MSW-A1")];
      cocFields = [...cocFields, ...createPayloadFields("COC")];
      // Create final payloads for each compliance type
      const a1Payload = createFinalPayload("A1", a1Fields);
      const mswPayload = createFinalPayload("MSW-A1", mswFields);
      const cocPayload = createFinalPayload("COC", cocFields);
      // Combine all payloads and filter out null values
      const payload = [a1Payload, mswPayload, cocPayload].filter(
        (item) => item !== null
      );
      // Check if any field is incomplete and prompt user if needed
      if (payload.some((item) => !item.is_complete)) {
        // questionnaire.filter((_field) => {
        //   if (_field?.required) {
        //     // values[_field.name]
        //   }
        // });
        setShowIncompleteInfoModal(true); // Open the incomplete information modal
        const userDecision = await waitForUserSubmitDecision(); // Wait for user input

        if (userDecision === "back") {
          return; // Stop execution
        }
      }

      // Dispatch payload and navigate accordingly
      dispatch(submitFormFieldData(payload)).then(() => {
        if (updateCompanyName) {
          dispatch(
            updatePersonaEntities({
              name: updateCompanyName,
              uuid: item.uuid,
            })
          )
            .unwrap()
            .then(() => {
              navigate("/complete-questionnaire");
            })
            .catch(() => {
              navigate("/complete-questionnaire");
            });
        } else navigate("/complete-questionnaire");
      });
    } else goToNextGroup();
  };

  return (
    <>
      <NavigationBar
        nextDoubleWidth={true}
        backFunc={determinedBackFunc}
        nextFunc={determinedNextFunc}
        cancelFunc={onBack}
        cancelVisible={true}
        nextText={isLastGroup ? "Save" : "Next"}
      />

      {showIncompleteInfoModal && (
        <MyModal>
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              <h2 style={{ marginBottom: 0, fontSize: "1.5rem" }}>
                Incomplete Information
              </h2>
            </div>

            <p
              style={{ textAlign: "center" }}
              className="d-flex align-items-center justify-content-center flex-wrap mt-2 mb-4"
            >
              It looks like some required fields are missing or incomplete.
              Please review the form and complete the highlighted sections
              before saving.
            </p>

            <div className="row w-100">
              <div className="col">
                <div className="d-flex w-100 justify-content-center gap-4">
                  <button
                    style={{
                      color: primaryColor,
                      borderColor: primaryColor,
                      backgroundColor: "white",
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      if (resolveDecisionRef.current) {
                        resolveDecisionRef.current("back"); // Resolve with 'back'
                      }
                      setShowIncompleteInfoModal(false); // Close the modal
                    }}
                  >
                    Back
                  </button>
                  <button
                    style={{
                      backgroundColor: primaryColor,
                      borderColor: primaryColor,
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      if (resolveDecisionRef.current) {
                        resolveDecisionRef.current("continue"); // Resolve with 'continue'
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </MyModal>
      )}
    </>
  );
};
