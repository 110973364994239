import React from "react";
export const TableLaoding = () => {
  return (
    <div
      style={{ height: "300px", width: "100%" }}
      className="d-flex align-items-center justify-content-center"
    >
      <span
        style={{ width: "50px", height: "50px" }}
        className="spinner-border spinner-border-sm me-1"
      ></span>
    </div>
  );
};
