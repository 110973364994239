import axios from "axios";

const API_URL = process.env.REACT_APP_BACK_END_URL;

const login = async (payload) => {
  try {
    // const response = await fetch(`${API_URL + "users/token/"}`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",

    //     ...{ Version: "CIBT" },
    //   },
    //   body: {
    //     email: username,
    //     password,
    //   },
    // });
    const tenant = localStorage.getItem("tenantsInfo");
    const tanentInfo = tenant ? JSON.parse(tenant) : {};
    const response = await axios.post(API_URL + "users/token/", payload, {
      headers: {
        Version: tanentInfo.tenant,
      },
    });
    if (
      response?.data?.message === "OTP has been successfully sent to your email"
    ) {
      return { sendOtp: true };
    }
    if (response?.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw new Error("Fail to login");
  }
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("tenantsInfo");
  localStorage.removeItem("AUTH0_TOKEN_RESPONSE");
  localStorage.removeItem("token");
};

const authService = {
  login,
  logout,
};

export default authService;
