import React from "react";
import { MyModal } from "../../../../components/common";
import { Loading } from "../../../../components/Loading.js";
import { useDispatch } from "react-redux";
import { updatePersonaEntities } from "../../../../slices/dashboard";
import { primaryColor } from "../../../../common/env.js";

const UpdateEntityModal = ({
  loading,
  entityUpdateName,
  setEntityUpdateName,
  updateEntryModal,
  setUpdateEntryModal,
  getPersonaEntitiesHandler,
}) => {
  const dispatch = useDispatch();

  return (
    <MyModal onClose={() => setUpdateEntryModal("")}>
      {loading && <Loading />}
      <div className="w-100">
        <div className="d-flex justify-content-between flex-wrap">
          <h2>Update entity details</h2>
        </div>
        <div className="d-flex flex-wrap w-100 pt-5 justify-content-between">
          <div style={{ width: "100%" }}>
            <label className="form-label">
              Entity name
              <span style={{ color: primaryColor }}>*</span>
            </label>
            <input
              value={entityUpdateName}
              onChange={(e) => setEntityUpdateName(e.target.value)}
              className="form-control"
              placeholder="Enter entity name"
              style={{ paddingRight: "50px" }}
            />
          </div>
        </div>

        <div className="row w-100">
          <div className="col">
            <div className="row mt-5">
              <div className="col">
                <div className="d-flex w-100 justify-content-center gap-3">
                  <button
                    style={{
                      color: primaryColor,
                      borderColor: primaryColor,
                      backgroundColor: "white",
                    }}
                    type="button"
                    className="btn"
                    onClick={() => {
                      setEntityUpdateName("");
                      setUpdateEntryModal("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!entityUpdateName}
                    style={{
                      backgroundColor: primaryColor,
                      borderColor: primaryColor,
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      dispatch(
                        updatePersonaEntities({
                          name: entityUpdateName,
                          uuid: updateEntryModal.uuid,
                        })
                      )
                        .unwrap()
                        .then(() => {
                          setEntityUpdateName("");
                          setUpdateEntryModal("");
                          getPersonaEntitiesHandler();
                        })
                        .catch(() => {});
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyModal>
  );
};

export default UpdateEntityModal;
