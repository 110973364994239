import React, { useEffect, useMemo, useState } from "react";
import filterLogo from "../../assets/images/filter.svg";
import { FormDate, SelectBox } from "../common";
import { apiFetchWrapper } from "../../utils/axiosApiWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getCountriesList, getPersonaEntities } from "../../slices/dashboard";
import useCountryList from "../../assets/shared/hooks/useCountryList";
import {
  getApplicationsStats,
  getFilledApplications,
} from "../../slices/filledApplications";
import { differenceInDays } from "date-fns";

export const Filter = ({
  setSearchQuery,
  setFilterCollaps,
  setPageNumber,
  setSortCondition,
  setAllStatusList,
}) => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const { getCountryCode } = useCountryList();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setFilterCollaps(!isVisible);
  };

  const API_URL = process.env.REACT_APP_BACK_END_URL;
  const { countriesList } = useSelector((state) => state.dashboard);
  const [allCompliaceType, setAllCompliaceType] = useState([]);
  const [selectedCompliaceType, setSelectedCompliaceType] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [arrangedCountryList, setArrangedCountryList] = useState([]);
  const [selectedHomeCountry, setSelectedHomeCountry] = useState("");
  const [selectedDestinationCountry, setSelectedDestinationCountry] =
    useState("");
  const [selectedStartDate, setSelecteddStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [personaEntities, setPersonaEntities] = useState([]);
  const [selectedPageSize, setSelectedPageSize] = useState(20);
  const [dateError, setDateError] = useState("");

  const allObj = useMemo(() => {
    return [{ label: "All", value: "" }];
  }, []);

  const getComplainceTypes = async () => {
    const url = API_URL + `compliance/types`;
    const res = await apiFetchWrapper.get(url, true);
    if (res.error) setAllCompliaceType([]);
    const newArray = res?.data?.results?.map((item) => ({
      label: item.description,
      value: item.type,
    }));
    setAllCompliaceType([...allObj, ...newArray]);
  };
  const getPersonaEntitiesHandler = (url) => {
    dispatch(getPersonaEntities(url))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };
  const getStatusList = async () => {
    const url = API_URL + `compliance/compliance-requests/status-options/`;
    const res = await apiFetchWrapper.get(url, true);
    if (res.error) setAllCompliaceType([]);
    const newArray = res?.data?.map((item) => ({
      label: item.display_name,
      value: item.value,
    }));
    setAllStatusList(res?.data ? res?.data : []);
    setStatusList([...allObj, ...newArray]);
  };
  const getPersonaList = async () => {
    const url = API_URL + `personas/entities/`;
    const res = await apiFetchWrapper.get(url, true);
    if (res.error) setAllCompliaceType([]);
    const newArray = res?.data?.results?.map((item) => ({
      label: item.name,
      value: item.uuid,
    }));
    setPersonaEntities([...allObj, ...newArray]);
  };
  const getCountryListHandler = () => {
    dispatch(getCountriesList())
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };
  useEffect(() => {
    getComplainceTypes();
    getPersonaEntitiesHandler();
    getStatusList();
    getCountryListHandler();
    getPersonaList();
  }, []);

  const getFilledApplicationsHandler = React.useCallback(
    (query) => {
      let url = `${API_URL}compliance/compliance-requests/`;
      dispatch(getFilledApplications(`${url}${query}`))
        .unwrap()
        .then(() => {
          setIsVisible(false);
          setFilterCollaps(false);
          setPageNumber(1);
          setSortCondition({});
        })
        .catch(() => {
          setIsVisible(false);
          setFilterCollaps(false);
        });
      dispatch(getApplicationsStats(query))
        .unwrap()
        .then(() => {
          setIsVisible(false);
        })
        .catch(() => {
          setIsVisible(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (countriesList && countriesList.length) {
      const formattedList = countriesList.map((_item) => ({
        label: _item.name,
        // value: _item.uuid,
        value: getCountryCode(_item.name),
      }));

      const newList = [...allObj, ...formattedList];

      setArrangedCountryList(
        newList?.sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [countriesList, allObj, getCountryCode]);

  const searchQueryHandler = () => {
    let searchquery = "";
    if (selectedCompliaceType)
      searchquery = `compliance_type=${selectedCompliaceType}`;
    if (selectedEntity)
      searchquery =
        searchquery + `${searchquery ? "&" : ""}persona=${selectedEntity}`;
    if (selectedStatus)
      searchquery =
        searchquery + `${searchquery ? "&" : ""}status=${selectedStatus}`;
    if (selectedHomeCountry)
      searchquery =
        searchquery +
        `${searchquery ? "&" : ""}home_country=${selectedHomeCountry}`;
    if (selectedDestinationCountry)
      searchquery =
        searchquery +
        `${searchquery ? "&" : ""}host_country=${selectedDestinationCountry}`;
    if (selectedStartDate)
      searchquery =
        searchquery +
        `${searchquery ? "&" : ""}created_after=${selectedStartDate}`;
    if (selectedEndDate)
      searchquery =
        searchquery +
        `${searchquery ? "&" : ""}created_before=${selectedEndDate}`;
    if (selectedPageSize)
      searchquery =
        searchquery + `${searchquery ? "&" : ""}page_size=${selectedPageSize}`;
    return searchquery;
  };

  const searchFilterHandler = () => {
    let url = `${API_URL}compliance/compliance-requests/`;
    const searchquery = searchQueryHandler();
    setSearchQuery(searchquery);
    getFilledApplicationsHandler(searchquery ? "?" + searchquery : "");
  };

  const resetFilterHandler = () => {
    setSelectedCompliaceType("");
    setSelectedDestinationCountry("");
    setSelectedHomeCountry("");
    setSelectedStatus("");
    setSelectedEntity("");
    setSelectedEndDate("");
    setSelecteddStartDate("");
    getFilledApplicationsHandler();
    setIsVisible(false);
    setFilterCollaps(false);
  };

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      const startDate = new Date(selectedStartDate);
      const endDate = new Date(selectedEndDate);
      const daysDifference = differenceInDays(endDate, startDate);
      if (daysDifference <= 0) {
        setDateError("End date must be after the start date.");
      } else {
        setDateError("");
      }
    } else {
      setDateError("");
    }
  }, [selectedStartDate, selectedEndDate]);

  return (
    <main
      className={`bg-white rounded shadow pt-2 px-2 pb-2 filter_main_div bg-dark`}
      style={{
        maxHeight: "57rem",
        zIndex: "99",
      }}
    >
      <div
        style={{
          backgroundColor: themeColor,
          color: "white",
          borderRadius: "12px",
          cursor: "pointer",
        }}
        className="d-flex justify-content-between w-100 p-2 px-3"
        onClick={() => toggleVisibility()}
      >
        <span className=" d-md-inline">Advanced filter</span>{" "}
        <img src={filterLogo} alt="fb" />
      </div>
      <div
        style={{ height: "400px", overflow: "auto" }}
        className={`filter_content ${
          isVisible ? "filter_expanded" : "filter_collapsed"
        }`}
      >
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}> By compliance type</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={selectedCompliaceType}
            onChange={(compliance_type) => {
              setSelectedCompliaceType(compliance_type?.value);
            }}
            options={allCompliaceType}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}> By employing entity</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={selectedEntity}
            onChange={(entity) => {
              setSelectedEntity(entity?.value);
            }}
            options={personaEntities}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>By status</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={selectedStatus}
            onChange={(entity) => {
              setSelectedStatus(entity?.value);
            }}
            options={statusList}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>By home country</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={selectedHomeCountry}
            onChange={(entity) => {
              setSelectedHomeCountry(entity?.value);
            }}
            options={arrangedCountryList}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>By destination country</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={selectedDestinationCountry}
            onChange={(entity) => {
              setSelectedDestinationCountry(entity?.value);
            }}
            options={arrangedCountryList}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>Page Size</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={selectedPageSize}
            onChange={(page) => {
              setSelectedPageSize(page?.value);
            }}
            options={[
              { label: "20", value: 20 },
              { label: "40", value: 40 },
              { label: "80", value: 80 },
              { label: "100", value: 100 },
              { label: "150", value: 150 },
              { label: "200", value: 200 },
            ]}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>Date from</span>
          <FormDate
            value={selectedStartDate}
            description=""
            isRequired={false}
            onChange={(name, value) => {
              setSelecteddStartDate(value);
            }}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>Date to</span>
          <FormDate
            value={selectedEndDate}
            description=""
            isRequired={false}
            error={dateError}
            onChange={(name, value) => {
              setSelectedEndDate(value);
            }}
          />
        </div>

        <div
          style={{ gap: "10px" }}
          className="d-flex justify-content-center mt-3"
        >
          <button
            style={{
              color: themeColor,
              borderColor: themeColor,
              backgroundColor: "white",
              width: "100%",
            }}
            type="button"
            className="btn  px-4  my-2"
            onClick={() => {
              resetFilterHandler();
            }}
          >
            Reset
          </button>
          <button
            style={{
              backgroundColor: themeColor,
              borderColor: themeColor,
              width: "100%",
            }}
            type="button"
            className="btn btn-danger  px-4  my-2"
            disabled={dateError}
            onClick={() => {
              searchFilterHandler();
            }}
          >
            Search
          </button>
        </div>
      </div>
    </main>
  );
};
