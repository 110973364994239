import React from 'react';
import { Heading, NormalText } from '../../components/common';

export const Terms = () => (
	<div className="container-fluid px-5 py-5 auto-scroll-y">
		<Heading>1. Introduction</Heading>
		<p>
			<NormalText color={'grey'}>
				These Terms of Use and Terms of Service ("Terms") govern your access to
				and use of the website, services, and applications offered by applies to
				CIBT Inc., CIBTVisas, Entriva, Newland Chase, Blair Counselor, our
				subsidiaries and affiliates, and any Services that link directly to
				these Terms (collectively, “we”, “our”, or “us”).
			</NormalText>
		</p>
		<Heading>2. Acceptance of terms</Heading>
		<p>
			<NormalText color={'grey'}>
				By accessing or using our website and services, you agree to be bound by
				these Terms. If you do not agree to these Terms, please refrain from
				using our services or proceeding further with the website use.
			</NormalText>
		</p>
		<Heading>3. Services provided</Heading>
		<p>
			<NormalText color={'grey'}>
				We offer an automated service for preparing and submitting
				government-required applications, such as A1 certificates of social
				security coverage. We reserve the right to modify, halt, or discontinue
				any feature of the website at any time without notice.
			</NormalText>
		</p>
		<Heading>4. User responsibilities</Heading>
		<p>
			<NormalText color={'grey'}>
				As a user, you agree to use our services and this website lawfully and
				not to misuse its content. You must refrain from introducing malicious
				software, attempting to gain unauthorized access, or otherwise acting in
				a way that negatively impacts the experience of other users. You As a
				user, you hold responsibility for the accuracy and completeness of any
				information you may be asked to provide to submit and support your
				application. Where our services include instructions directing you to
				complete additional steps offline, you hold responsibility for
				completing these steps, unless we have been authorised to act on your
				behalf by a duly executed power of attorney or authorization form in
				compliance with the national laws. Examples of such offline steps may
				include posting a physical copy of the application to the named
				governmental authority.
			</NormalText>
		</p>
		<Heading>5. Accuracy of regulatory information</Heading>
		<p>
			<NormalText color={'grey'}>
				While we strive to maintain accurate and up-to-date information related
				to the government-required applications, we cannot guarantee the
				reliability or accuracy of any information provided where such changes
				are made with short or no notice or they have not been made public.
			</NormalText>
		</p>
		<Heading>6. Limitation of Liability</Heading>
		<p>
			<NormalText color={'grey'}>
				The website and its contents are provided "as is." We do not guarantee
				the accuracy, completeness, or usefulness of any content provided. To
				the fullest extent permitted by law, we will not be liable for any
				direct, indirect, incidental, or consequential damages resulting from
				the use or inability to use the website.
			</NormalText>
		</p>
		<Heading>7. Privacy and cookie policy</Heading>
		<p>
			<NormalText color={'grey'}>
				Your use of the Website is also governed by our Privacy Policy, which
				can be found at https://newlandchase.com/privacy/
			</NormalText>
		</p>
		<Heading>8. Changes to Terms</Heading>
		<p>
			<NormalText color={'grey'}>
				We reserve the right to modify these Terms at any time. Changes will be
				effective upon posting.
			</NormalText>
		</p>
		<Heading>9. Governing Law</Heading>
		<p>
			<NormalText color={'grey'}>
				These Terms are governed by the laws of England and Wales, without
				regard to its conflict of laws rules.
			</NormalText>
		</p>
	</div>
);
