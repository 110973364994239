import { useMemo } from "react";

import introSelected from "../../assets/images/intro-selected";
import introLight from "../../assets/images/intro-light";
import actionRequiredLight from "../../assets/images/action-light";
import actionSelecetd from "../../assets/images/action";
import map from "../../assets/images/map-light";
import mapSelected from "../../assets/images/map-selected";
import dropDownSelected from "../../assets/images/drop-down-selected";
import dropDownSelectedLight from "../../assets/images/drop-down-selected-light.jsx";
import dropDownLight from "../../assets/images/drop-down-light";
import ICON_NAMES from "./iconeNames";
import lightThemeDropDown from "../../assets/images/drop-down-light-theme";
import addIcone from "../../assets/images/add-icone.jsx";
import edit from "../../assets/images/edit.jsx";
import deleteIcone from "../../assets/images/delete.jsx";
import crossIcone from "../../assets/images/cross.jsx";

const Icons = {
  [ICON_NAMES.INTROSELECTED]: introSelected,
  [ICON_NAMES.INTROLIGHT]: introLight,
  [ICON_NAMES.ACTIONREQUIREDLIGHT]: actionRequiredLight,
  [ICON_NAMES.ACTIONSELECTED]: actionSelecetd,
  [ICON_NAMES.MAP]: map,
  [ICON_NAMES.MAPSELECTED]: mapSelected,
  [ICON_NAMES.DROPDOWNSELECTED]: dropDownSelected,
  [ICON_NAMES.DROPDOWNSELECTEDLIGHT]: dropDownSelectedLight,
  [ICON_NAMES.DROPDOWNLIGHT]: dropDownLight,
  [ICON_NAMES.LIGHTTHEMEDROPDOWN]: lightThemeDropDown,
  [ICON_NAMES.ADDICONE]: addIcone,
  [ICON_NAMES.EDIT]: edit,
  [ICON_NAMES.DELETICONE]: deleteIcone,
  [ICON_NAMES.CROSSICONE]: crossIcone,
};

const Icon = ({ name, ...rest }) => {
  const { ...other } = rest;
  const IconComponent = Icons[name];

  return useMemo(() => <IconComponent {...other} />, [IconComponent, other]);
};

export { ICON_NAMES };

export default Icon;
