import React, { useState } from 'react';
import starFill from '../assets/images/star.svg';
import star from '../assets/images/star-light.svg';
import rate from '../assets/images/rate.png';
import { HelpText, TextInput } from './common';

function Settings() {
	const [emailCheck, setEmailCheck] = useState({});
	return (
		<>
			<section className="listing-section">
				<div className="container-fluid">
					<div className="col-md-12">
						<div className="d-flex gap-3">
							<h2>Email Notification</h2>
							<HelpText helpText={'Email Notification help text here!'} />
						</div>
						<hr />
						<div className="d-flex flex-column w-100 h-100">
							<div>
								<div className="d-flex ms-3 justify-content-between">
									<div className="normal-bold">Intermediate notification</div>
									<div>
										<label className="cswitch">
											<input
												className="cswitch-input"
												type="checkbox"
												checked={emailCheck?.notification}
												onChange={(e) => {
													setEmailCheck({
														notification: e.target.checked,
													});
												}}
											/>
											<span className="cswitch-trigger wrapper"></span>
										</label>
									</div>
								</div>
								<hr />
							</div>
							<div>
								<div className="d-flex ms-3 justify-content-between">
									<div className="normal-bold">Daily Summary</div>
									<div>
										<label className="cswitch">
											<input
												className="cswitch-input"
												type="checkbox"
												checked={emailCheck?.dailySummary}
												onChange={(e) => {
													setEmailCheck({
														dailySummary: e.target.checked,
													});
												}}
											/>
											<span className="cswitch-trigger wrapper"></span>
										</label>
									</div>
								</div>
								<hr />
							</div>
							<div>
								<div className="d-flex ms-3 justify-content-between">
									<div className="normal-bold">Weekly Summary</div>
									<div>
										<label className="cswitch">
											<input
												className="cswitch-input"
												type="checkbox"
												checked={emailCheck?.weeklySummary}
												onChange={(e) => {
													setEmailCheck({
														weeklySummary: e.target.checked,
													});
												}}
											/>
											<span className="cswitch-trigger wrapper"></span>
										</label>
									</div>
								</div>
								<hr />
							</div>
						</div>

						<div className="d-flex flex-column gap-2 mt-4 col-12 col-sm-3">
							<TextInput label="Delegate Access" />
							<button className="btn btn-danger mt-2">Submit</button>
						</div>
					</div>
				</div>
			</section>

			<div
				className="modal fade"
				id="exampleModal"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-sm modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-body text-center p-4 m-1">
							<img
								src={rate}
								alt="rate"
							/>
							<h3 className="mt-4">Your information has been received</h3>
							<p>
								You will soon receive an email with further information
								regarding your A1 application.'
							</p>

							<hr />
							<h3>Please rate your experience with this app.</h3>

							<div className="rating my-4">
								<img
									className="mx-1"
									width="75"
									src={starFill}
									alt="star"
								/>
								<img
									className="mx-1"
									width="75"
									src={starFill}
									alt="star"
								/>
								<img
									className="mx-1"
									width="75"
									src={starFill}
									alt="star"
								/>
								<img
									className="mx-1"
									width="75"
									src={star}
									alt="star"
								/>
								<img
									className="mx-1"
									width="75"
									src={star}
									alt="star"
								/>
							</div>

							<hr />

							<h3 className="mb-3 pb-1">Write a review</h3>
							<form>
								<div className="mb-4">
									<textarea
										className="form-control"
										placeholder="Star writing here"
										rows="5"
									></textarea>
								</div>

								<button
									type="button"
									className="btn btn-danger px-5"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Settings;
