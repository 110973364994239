import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import powerOfAttorneyService from "../services/powerOfAttorney.service";
import { setMessage } from "./message";
import { base64ToFile } from "../utils/fileConversions";
const API_URL = process.env.REACT_APP_BACK_END_URL;

const initialState = {
  pendingPoaEntities: [],
  completedPoaEntities: [],
  isLoading: false,
  error: null,
  pendingNextPageUrl: null,
  pendingPreviousPageUrl: null,
  pendingPageSize: 1,
  pendingCount: 0,
  completedNextPageUrl: null,
  completedPreviousPageUrl: null,
  completedPageSize: 1,
  completedCount: 0,
  poaLoading: false,
};

export const getPoaEntities = createAsyncThunk(
  "powerOfAttorney/getPoaEntities",
  async ({ url, is_complete }, thunkAPI) => {
    try {
      const data = await powerOfAttorneyService.getPoaEntities(
        url,
        is_complete
      );
      const res = data.data;
      res.completedStatus = is_complete;
      if (data?.data) return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const uplaodPOA = createAsyncThunk(
  "powerOfAttorney/uplaodPOA",
  async (payload, thunkAPI) => {
    try {
      const { filename, file, uuid, employer_sub_entity } = payload;
      const file_names = [filename];

      const data = await powerOfAttorneyService.uploadDocumentUrl({
        file_names,
      });
      const uploadedFile = base64ToFile(file, filename);
      if (data?.data) {
        for (const item of data?.data) {
          await powerOfAttorneyService.uploadDocument(
            item.pre_signed_url,
            uploadedFile
          );
          const apiUrl = API_URL + `personas/entity-poa/${uuid}/`;

          await powerOfAttorneyService.updatePoaEntities(apiUrl, {
            signed_poa_aws_object_key: item.object_key,
            employer_sub_entity,
            is_complete: true,
          });
        }

        return data.data;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const dashboardSlice = createSlice({
  name: "powerOfAttorney",
  initialState,
  extraReducers: {
    [getPoaEntities.pending]: (state, action) => {
      state.loading = true;
    },
    [getPoaEntities.fulfilled]: (state, action) => {
      if (action.payload.completedStatus) {
        state.completedPoaEntities = action.payload.results;
        state.completedNextPageUrl = action.payload.next;
        state.completedPreviousPageUrl = action.payload.previous;
        state.completedPageSize = action.payload.page_size;
        state.completedCount = action.payload.count;
        state.loading = false;
      } else {
        state.pendingPoaEntities = action.payload.results;
        state.pendingNextPageUrl = action.payload.next;
        state.pendingPreviousPageUrl = action.payload.previous;
        state.pendingPageSize = action.payload.page_size;
        state.pendingCount = action.payload.count;
        state.loading = false;
      }
    },
    [getPoaEntities.rejected]: (state, action) => {
      state.loading = false;
    },
    [uplaodPOA.pending]: (state, action) => {
      state.poaLoading = true;
    },
    [uplaodPOA.fulfilled]: (state, action) => {
      state.poaLoading = false;
    },
    [uplaodPOA.rejected]: (state, action) => {
      state.poaLoading = false;
    },
  },
});

const { reducer } = dashboardSlice;

export default reducer;
