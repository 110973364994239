import React, { useCallback, useEffect, useState } from "react";
import { TextInput } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountriesList,
  getPersonaEntities,
} from "../../../slices/dashboard";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { useNavigate } from "react-router-dom";
import { TableLaoding } from "../../../components/common/Loading/TableLoading.js";
import debounce from "lodash/debounce";
import Icon, { ICON_NAMES } from "../../../components/Icone/index.jsx";
import styles from "./index.module.css";
import { startQuestionareLoading } from "../../../slices/questionnaire.js";
import DeleteModal from "./components/delete-modal.jsx";
import AddEntitiesModal from "./components/add-entities-modal.jsx";
import { extractOrdering } from "../../../functions/extractOrderingFromTableSort.js";
import { getFormattedDate } from "../../../functions/getFormattedDate.js";
import UpdateEntityModal from "./components/update-entity-modal.jsx";
import { Table } from "../../../components/common/Table";
import { primaryColor } from "../../../common/env.js";

const API_URL = process.env.REACT_APP_BACK_END_URL;

export const CompleteQuestionnaire = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCountryName } = useCountryList();
  const {
    personaEntities,
    loading,
    nextPageUrl,
    previousPageUrl,
    page_size,
    count,
  } = useSelector((state) => state.dashboard);
  const [addEntitiesModal, setAddEntitiesModal] = React.useState(false);
  const [updateEntryModal, setUpdateEntryModal] = React.useState("");
  const [entityUpdateName, setEntityUpdateName] = React.useState("");
  const [searchText, setSearchText] = useState("");

  const [saveLoading, setSaveLoading] = useState(false);
  const [sortCondition, setSortCondition] = useState({});
  const [deleteEntity, setDeleteEntity] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [inputPageNumber, setInputPageNumber] = useState("1");

  const handleSorting = (field) => {
    setSortCondition((prev) => {
      let order = "";

      if (prev.field === field) {
        order = prev.order === "" ? "-" : "";
      }

      return {
        field,
        order,
      };
    });
  };

  useEffect(() => {
    const { field, order } = sortCondition;

    if (field) {
      const url =
        API_URL +
        `personas/entities/?compliance_type=A1,MSW-A1,COC&ordering=${order}${field}&page_size=${pageSize}`;
      localStorage.setItem(`questionnaireSort`, url);
      setInputPageNumber("1");
      getPersonaEntitiesHandler(url);
    }
  }, [sortCondition]);

  const getPersonaEntitiesHandler = (url) => {
    const { field, order } = sortCondition;
    const baseUrl = `${API_URL}personas/entities/?compliance_type=A1,MSW-A1,COC`;
    let finalUrl = url || `${baseUrl}&page_size=${pageSize}&page=${pageNumber}`;

    if (field) {
      finalUrl += `&ordering=${order}${field}`;
    }
    dispatch(getPersonaEntities(finalUrl))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  const getCountryListHandler = () => {
    dispatch(getCountriesList())
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    const questionnaireSort = localStorage.getItem(`questionnaireSort`);
    const savedPageSize = localStorage.getItem("questionnairePageSize");

    if (savedPageSize) {
      setPageSize(Number(savedPageSize));
    }

    if (questionnaireSort) {
      setSortCondition(extractOrdering(questionnaireSort));
    } else {
      getPersonaEntitiesHandler(null);
    }
    getCountryListHandler();
  }, []);

  const fetchNextPage = () => {
    if (nextPageUrl) {
      const nextPage = Number(pageNumber) + 1;
      setInputPageNumber(nextPage.toString());
      setPageNumber(nextPage);
      getPersonaEntitiesHandler(nextPageUrl);
    }
  };

  const fetchPreviousPage = () => {
    if (previousPageUrl) {
      const previousPage = Number(pageNumber) - 1;
      setInputPageNumber(previousPage.toString());
      setPageNumber(previousPage);
      getPersonaEntitiesHandler(previousPageUrl);
    }
  };

  const handleGetForm = (item, address, fromModal) => {
    dispatch(startQuestionareLoading());
    navigate("/questionnaire", {
      state: { item, address, fromModal },
    });
  };

  const debouncedGetPersonaEntitiesHandler = useCallback(
    debounce((url) => {
      getPersonaEntitiesHandler(url);
    }, 1300),
    []
  );

  const handleSearch = (value) => {
    if (!value) {
      debouncedGetPersonaEntitiesHandler(null);
    } else if (value.length) {
      const url =
        API_URL +
        `personas/entities/?compliance_type=A1,MSW-A1,COC&search=${value}&page_size=${pageSize}`;
      debouncedGetPersonaEntitiesHandler(url);
    }
    setSearchText(value);
  };

  const totalPages = Math.ceil(count / page_size) || 0;

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    localStorage.setItem("questionnairePageSize", newSize);
    setPageNumber(1);
    setInputPageNumber("1");
    // Apply current sorting when changing page size
    const { field, order } = sortCondition;
    let url;
    if (field) {
      url =
        API_URL +
        `personas/entities/?compliance_type=A1,MSW-A1,COC&ordering=${order}${field}&page_size=${newSize}`;
    } else {
      url =
        API_URL +
        `personas/entities/?compliance_type=A1,MSW-A1,COC&page_size=${newSize}`;
    }
    getPersonaEntitiesHandler(url);
  };

  const debouncedPageChange = useCallback(
    debounce((pageNum) => {
      const numericPage = parseInt(pageNum);
      if (numericPage && numericPage <= totalPages) {
        const { field, order } = sortCondition;
        let url = `${API_URL}personas/entities/?compliance_type=A1,MSW-A1,COC&page=${numericPage}&page_size=${pageSize}`;

        // Add sorting if active
        if (field) {
          url += `&ordering=${order}${field}`;
        }

        getPersonaEntitiesHandler(url);
        setPageNumber(numericPage);
      }
    }, 500),
    [totalPages, pageSize, sortCondition] // Added sortCondition to dependencies
  );

  const handlePageNumberChange = (e) => {
    const value = e.target.value;
    setInputPageNumber(value);
    if (value === "" || isNaN(value)) return;
    debouncedPageChange(value);
  };

  const columns = [
    {
      key: "country_name",
      label: "Country",
      sortable: true,
      render: (row) => getCountryName(row.country_code),
    },
    {
      key: "name",
      label: "Employing entity",
      sortable: true,
      render: (row) => (
        <div
          onClick={() => {
            setUpdateEntryModal(row);
            setEntityUpdateName(row.name);
          }}
          className="cursor-pointer"
        >
          {row.name}
          <Icon name={ICON_NAMES.EDIT} />
        </div>
      ),
    },
    {
      key: "status",
      label: "Status",
      sortable: true,
      render: (row) =>
        row.is_complete ? (
          "Complete"
        ) : (
          <div style={{ color: primaryColor }}>Incomplete</div>
        ),
    },
    {
      key: "actions",
      label: "Action",
      sortable: false,
      render: (row) => (
        <div style={{ gap: "15px" }} className="d-flex">
          <div
            onClick={() => handleGetForm(row, {}, false)}
            style={{ color: primaryColor }}
            className={`${styles.tableAction} cursor-pointer`}
          >
            <Icon name={ICON_NAMES.EDIT} />
            <span>Edit</span>
          </div>
          <div
            onClick={() => setDeleteEntity(row.uuid)}
            style={{ color: primaryColor }}
            className={`${styles.tableAction} cursor-pointer`}
          >
            <Icon name={ICON_NAMES.DELETICONE} />
            <span>Delete</span>
          </div>
        </div>
      ),
    },
    {
      key: "updated_at",
      label: "Last modified",
      sortable: true,
      render: (row) => (
        <div className="d-inline-block" style={{ whiteSpace: "nowrap" }}>
          {getFormattedDate(row.updated_at)}
        </div>
      ),
    },
  ];

  return (
    <main>
      {loading ? (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
          style={{
            zIndex: "9999",
          }}
        >
          <TableLaoding />
        </div>
      ) : null}
      <section className="listing-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="d-flex gap-3">
              <h2>Complete questionnaire</h2>
              {/* <HelpText helpText={"Help text here!"} /> */}
            </div>
            <p>
              Please complete the following questionnaires which are required
              for the filing of A1 applications in each country.
            </p>
            <div className="d-flex mb-3 flex-wrap flex-md-nowrap gap-2">
              <div className="w-100">
                <div className="w-50" style={{ minWidth: "200px" }}>
                  <TextInput
                    label="Entity"
                    value={searchText}
                    isRequired={false}
                    placeHolder="Search here"
                    onChange={(name, value) => {
                      handleSearch(value);
                    }}
                    Icon={
                      <div className="pb-1">
                        <i className="h-100 bi bi-search" />
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="w-100 text-end">
                <button
                  style={{
                    backgroundColor: primaryColor,
                    borderColor: primaryColor,
                  }}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setAddEntitiesModal(true)}
                >
                  Add entity
                </button>
              </div>
            </div>

            {/* TABLE */}
            <Table
              columns={columns}
              data={personaEntities}
              pageSize={pageSize}
              totalPages={totalPages}
              inputPageNumber={inputPageNumber}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              previousPageUrl={previousPageUrl}
              nextPageUrl={nextPageUrl}
              onPreviousPage={fetchPreviousPage}
              onNextPage={fetchNextPage}
              onSort={handleSorting}
            />
          </div>
        </div>

        {deleteEntity && (
          <DeleteModal
            getPersonaEntitiesHandler={getPersonaEntitiesHandler}
            deleteEntity={deleteEntity}
            setDeleteEntity={setDeleteEntity}
            saveLoading={saveLoading}
          />
        )}
        {addEntitiesModal && (
          <AddEntitiesModal
            setSaveLoading={setSaveLoading}
            saveLoading={saveLoading}
            handleGetForm={handleGetForm}
            setAddEntitiesModal={setAddEntitiesModal}
            getPersonaEntitiesHandler={getPersonaEntitiesHandler}
          />
        )}
        {updateEntryModal && (
          <UpdateEntityModal
            loading={loading}
            entityUpdateName={entityUpdateName}
            setEntityUpdateName={setEntityUpdateName}
            updateEntryModal={updateEntryModal}
            setUpdateEntryModal={setUpdateEntryModal}
            getPersonaEntitiesHandler={getPersonaEntitiesHandler}
          />
        )}
      </section>
    </main>
  );
};
