import { configureStore } from "@reduxjs/toolkit";
import loggerMiddleware from "redux-logger";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import dashboardReduce from "./slices/dashboard";
import layoutReduce from "./slices/layout";
import questionnairReducer from "./slices/questionnaire";
import powerOfAttorneyReducer from "./slices/powerOfAttorney";
import filledApplicationsReducer from "./slices/filledApplications";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  dashboard: dashboardReduce,
  layout: layoutReduce,
  questionnaire: questionnairReducer,
  powerOfAttorney: powerOfAttorneyReducer,
  filledApplications: filledApplicationsReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => {
    if (process.env.REACT_APP_ENV === "dev") {
      return getDefaultMiddleware().concat(loggerMiddleware);
    }

    return getDefaultMiddleware();
  },
});

export default store;
