import { useEffect } from "react";
import { MdClose, MdFullscreen, MdRemove } from "react-icons/md";

export const VideoModal = ({
  setIsVideoMinimize,
  videoPage,
  isVideoMinimize,
  width = "60%",
  link = "",
  onHide = () => {},
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onHide(false);
        setIsVideoMinimize(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onHide, setIsVideoMinimize]);

  return (
    <div
      className="fixed z-20 overflow-y-auto top-0  left-0"
      id="modal"
      onClick={() => {
        onHide(false);
        setIsVideoMinimize(false);
      }}
      style={{
        width: `${isVideoMinimize ? "" : "100vw"}`,
        left: "0",
        zIndex: "9999",
        position: "fixed",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        className={`d-flex align-items-center justify-content-center min-vh-100 pt-4  pb-20 text-center sm-block sm-p-0 ${
          isVideoMinimize ? "w-0 h-0 px-0" : "px-4"
        }`}
      >
        <div
          className={`fixed inset-0 transition-opacity ${
            isVideoMinimize ? "w-0 h-0" : ""
          }`}
        >
          <div
            className={`position-absolute inset-0 bg-black ${
              isVideoMinimize ? "opacity-0 w-0 h-0" : "opacity-50"
            }`}
          />
        </div>
        <span className="d-inline-block align-middle d-sm-none h-100" />
        <div
          onClick={(e) => e.stopPropagation()}
          className={`d-inline-block align-center bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm-align-middle ${
            !isVideoMinimize ? "" : ""
          }`}
          style={{
            width: isVideoMinimize ? "253px" : width,
            maxWidth: "100%",
            maxHeight: "82vh",
            padding: isVideoMinimize ? "0" : "",
            position: isVideoMinimize ? "fixed" : "relative",
            left: isVideoMinimize ? "9px" : "auto",
            bottom: isVideoMinimize ? "62px" : "auto",
          }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {/* Close icon */}
          <div
            className="position-absolute w-100 d-flex justify-content-end p-2"
            style={{
              height: "50px",
              backgroundImage:
                "linear-gradient(180deg, rgba(38,38,38,1) 10%, rgba(247,247,247,0) 100%)",
              zIndex: 10,
            }}
          >
            <button
              style={{
                background: "transparent",
                border: "none",
              }}
            >
              <MdClose
                onClick={() => {
                  onHide(false);
                  setIsVideoMinimize(false);
                }}
                style={{
                  border: "1px solid white",
                }}
                className="text-white rounded-circle p-1 me-2"
                size={30}
              />
            </button>
            <button
              style={{
                background: "transparent",
                border: "none",
              }}
            >
              {isVideoMinimize ? (
                <MdFullscreen
                  onClick={() => {
                    setIsVideoMinimize(false);
                  }}
                  style={{ border: "1px solid white" }}
                  className="text-white rounded-circle p-1"
                  size={30}
                />
              ) : (
                <MdRemove
                  onClick={() => {
                    setIsVideoMinimize(videoPage);
                  }}
                  style={{ border: "1px solid white" }}
                  className="text-white rounded-circle p-1"
                  size={30}
                />
              )}
            </button>
          </div>
          <div>
            <div style={{ width: "100%", height: "100%" }}>
              <video className="w-100 h-100" src={link} controls autoPlay />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
