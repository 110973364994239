import countryList from "country-list";
import {
  findKey,
  toPairs,
  fromPairs,
  mapKeys,
  assign,
  sortBy,
  values,
} from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

const useCountryList = () => {
  const [countryObject, setCountryObject] = useState({});

  const countryObjectValues = useMemo(
    () => values(countryObject),
    [countryObject]
  );

  useEffect(() => {
    const countryData = mapKeys(countryList.getCodeList(), (_, key) =>
      key.toUpperCase()
    );

    const countryPair = assign({}, countryData, {
      UK: "United Kingdom",
      CZ: "Czech Republic",
    });

    const countryEntries = toPairs(countryPair);

    const sortedEntries = sortBy(countryEntries, 1);

    const sortedObj = fromPairs(sortedEntries);

    setCountryObject(sortedObj);
  }, []);

  const getCountryCode = useCallback(
    (value) => {
      const key = findKey(countryObject, (val) => val === value);
      return key ?? "";
    },
    [countryObject]
  );

  const getCountryName = useCallback(
    (value) => {
      return countryObject[value];
    },
    [countryObject]
  );

  return {
    countryObject,
    countryObjectValues,
    getCountryCode,
    getCountryName,
  };
};

export default useCountryList;
