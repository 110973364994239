import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiFetchWrapper } from "../../../utils/axiosApiWrapper";
import styles from "./index.module.css";
import jsPDF from "jspdf";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { toast } from "react-toastify";
import Icon, { ICON_NAMES } from "../../Icone";
import parse from "html-react-parser";

export const ShowInstructionModal = ({ setSelectedEntity, selectedEntity }) => {
  const { getCountryName } = useCountryList();

  var doc = new jsPDF();

  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const modalRef = useRef(null);
  const mountRef = useRef(false);

  const handleOpenModal = useCallback(async () => {
    try {
      if (!selectedEntity?.country_code) return;
      setLoading(true);
      const url = `personas/poa-instructions?country_code=${selectedEntity?.country_code}`;

      const response = await apiFetchWrapper.getHtml(url);
      setLoading(false);
      const htmlData = await response.text();
      if (htmlData) {
        setHtmlContent(htmlData);
        setTimeout(() => {
          const button = document?.getElementById("svgCopyButton");
          const tooltip = document?.getElementById("copyCustomTooltip");

          if (button) {
            button.onclick = function () {
              const numberToCopy =
                document.getElementById("companyNumber").innerText;
              toast.success("Copied!");
              navigator.clipboard.writeText(numberToCopy);
            };
            button.addEventListener("mouseover", function () {
              tooltip.style.display = "block";
            });

            button.addEventListener("mouseout", function () {
              tooltip.style.display = "none";
            });
          }
        }, 1000);
      } else {
        console.error("No HTML content received");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching HTML content:", error);
    }
  }, [selectedEntity]);
  useEffect(() => {
    handleOpenModal();
  }, [handleOpenModal]);

  useEffect(() => {
    document.body.classList.add("body-overflowHidden");

    return () => {
      document.body.classList.remove("body-overflowHidden");
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setSelectedEntity({});
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setSelectedEntity]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!mountRef.current) {
        mountRef.current = true;
      } else if (modalRef.current && !modalRef?.current?.contains(e.target)) {
        setSelectedEntity({});
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  async function imageUrlToDataUrl(imageUrl) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      toast.error("Error while Fetching image");
    }
  }

  const downloadPdf = async () => {
    try {
      setDownloading(true);
      //get parent div
      const divContent = document.getElementById("showInstructionMainDIv");

      // get all images and load their src
      const images = divContent.querySelectorAll("img");
      for (let i = 0; i < images.length; i++) {
        const img = images[i];
        const imageUrl = img.src;
        const dataUrl = await imageUrlToDataUrl(imageUrl);
        img.setAttribute("src", dataUrl);
      }

      doc.html(divContent, {
        callback: function (doc) {
          const country_name = getCountryName(selectedEntity.country_code);
          // Save the PDF
          doc.save(
            `${country_name} - ${selectedEntity.employer_sub_entity_name} - Power of Attorney Instructions.pdf`
          );
        },
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        x: 0,
        y: 0,
        width: 190,
        windowWidth: 675,
      });
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };

  return (
    <div className={styles.modalParent}>
      <div ref={modalRef} className={`shadow bg-white ${styles.modalBody}`}>
        <div>
          <div className="d-flex justify-content-end">
            <div
              onClick={() => setSelectedEntity({})}
              className="cursor-pointer"
            >
              <Icon name={ICON_NAMES.CROSSICONE} />
            </div>
          </div>
          <div id="showInstructionMainDIv" className="w-100">
            <h4>
              {getCountryName(selectedEntity.country_code)} Power of Attorney
              (POA) Instructions
            </h4>

            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <span
                  style={{ width: "50px", height: "50px" }}
                  className="spinner-border spinner-border-sm me-1"
                ></span>
              </div>
            ) : (
              <>
                <div
                  className={`flex flex-col gap-4 my-3 ${styles.htmlContent}`}
                >
                  {htmlContent ? (
                    parse(htmlContent)
                  ) : (
                    <p>No content to display</p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex mt-5 gap-2 justify-content-center">
              <button
                style={{
                  color: themeColor,
                  borderColor: themeColor,
                  backgroundColor: "white",
                  width: "150px",
                }}
                type="button"
                className="btn"
                onClick={() => setSelectedEntity({})}
              >
                Close
              </button>
              <button
                style={{
                  width: "150px",
                }}
                type="button"
                className="btn btn-danger"
                onClick={() => downloadPdf()}
                disabled={downloading}
              >
                {downloading && (
                  <span className="spinner-border spinner-border-sm me-1"></span>
                )}
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
