import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import useCountryList from "../../../assets/shared/hooks/useCountryList.js";
import { handleDownload } from "../../../utils/handleDownload.js";
import { HelpText } from "../../../components/common/index.jsx";
import { getFormattedDate } from "../../../functions/getFormattedDate.js";
import { getPoaEntities } from "../../../slices/powerOfAttorney.js";
import debounce from "lodash.debounce";
import { API_URL } from "../../../common/env.js";
import { Table } from "../../../components/common/Table/index.jsx";
import { extractOrdering } from "../../../functions/extractOrderingFromTableSort.js";
import { TitleText } from "../../../components/TitleText/index.js";
import { TableLaoding } from "../../../components/common/Loading/TableLoading.js";

export default function CompletePowerOfAttorneyTable({
  setSelectedEntity,
  setUploadPOA,
}) {
  const dispatch = useDispatch();
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const themeDisabledColor = process.env.REACT_APP_LIGHT_PRIMARY_DISABLE;
  const { getCountryName } = useCountryList();
  const {
    completedPoaEntities,
    completedNextPageUrl,
    completedPreviousPageUrl,
    completedCount,
    completedPageSize,
    loading,
  } = useSelector((state) => state.powerOfAttorney);

  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputPageNumber, setInputPageNumber] = useState("1");
  const [sortCondition, setSortCondition] = useState({});

  const totalPages = Math.ceil(completedCount / completedPageSize) || 0;
  const columns = [
    {
      key: "country_name",
      label: "Country",
      sortable: true,
      render: (row) => (
        <div
          className={
            row.requires_specific_person_signature ? "prefilled-color" : ""
          }
        >
          {getCountryName(row.country_code)}
        </div>
      ),
    },
    {
      key: "name",
      label: "Employing entity",
      sortable: true,
    },
    {
      key: "status",
      label: "POA status",
      render: (row) => (row.is_complete ? "Submitted" : "Pending"),
    },
    {
      key: "instructions",
      label: "Instructions",
      render: (row) => (
        <div
          onClick={() => setSelectedEntity(row)}
          style={{ color: themeColor }}
          className="cursor-pointer"
        >
          Show Instructions
        </div>
      ),
    },
    {
      key: "download",
      label: "Download POA",
      render: (row) => {
        const isDownloadUrl =
          row.is_online_submission === false ? true : row.is_complete;
        const downloadUrl =
          row.template_poa_url || row.country_poa_pre_signed_urls[0];

        return !isDownloadUrl ? (
          <div>
            <HelpText
              noPadding={true}
              helpText="No POA template available."
              isIconeHide={true}
              content={<div>Download POA template</div>}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              if (!isDownloadUrl || !row.requires_employer_poa) return;
              const fileSavingName = `${getCountryName(row.country_code)} - ${
                row.employer_sub_entity_name
              } - Power of Attorney`;
              handleDownload(downloadUrl, fileSavingName);
            }}
            style={{
              color: isDownloadUrl ? themeColor : themeDisabledColor,
              cursor: isDownloadUrl ? "pointer" : "not-allowed",
            }}
          >
            Download POA template
          </div>
        );
      },
    },
    {
      key: "upload",
      label: "Upload POA",
      render: (row) => {
        const isDownloadUrl =
          row.is_online_submission === false ? true : row.is_complete;

        return !isDownloadUrl ? (
          <div>
            <HelpText
              noPadding={true}
              helpText="No POA upload required."
              isIconeHide={true}
              content={<div>Upload POA</div>}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              if (!isDownloadUrl || !row.requires_employer_poa) return;
              setUploadPOA(row);
            }}
            style={{
              color: isDownloadUrl ? themeColor : themeDisabledColor,
              cursor: isDownloadUrl ? "pointer" : "not-allowed",
            }}
          >
            Upload POA
          </div>
        );
      },
    },
    {
      key: "signed",
      label: "Signed POA",
      render: (row) => {
        const reviewUrl = row.signed_poa_url;
        return (
          <div>
            {reviewUrl ? (
              <div
                onClick={() => {
                  handleDownload(reviewUrl, row.uuid + "poa_signed");
                }}
                className="text-danger cursor-pointer"
              >
                Review completed POA
              </div>
            ) : (
              "Not completed"
            )}
          </div>
        );
      },
    },
    {
      key: "updated_at",
      label: "Last modified",
      sortable: true,
      render: (row) => (
        <div className="d-inline-block" style={{ whiteSpace: "nowrap" }}>
          {getFormattedDate(row.updated_at)}
        </div>
      ),
    },
  ];

  const debouncedPageChange = useCallback(
    debounce((pageNum) => {
      const numericPage = parseInt(pageNum);
      if (numericPage && numericPage <= totalPages) {
        const { field, order } = sortCondition;
        let url = `${API_URL}personas/entity-poa/?is_complete=true&page=${numericPage}&page_size=${completedPageSize}`;

        // Add sorting if active
        if (field) {
          url += `&ordering=${order}${field}`;
        }

        getCompletedPoaEntitiesHandler(url);
        setPageNumber(numericPage);
      }
    }, 500),
    [totalPages, completedPageSize, sortCondition] // Added sortCondition to dependencies
  );

  const getCompletedPoaEntitiesHandler = React.useCallback(
    (url) => {
      dispatch(getPoaEntities({ url, is_complete: true }))
        .unwrap()
        .then(() => {})
        .catch(() => {});
    },
    [dispatch]
  );

  const handlePageNumberChange = (e) => {
    const value = e.target.value;
    setInputPageNumber(value);
    if (value === "" || isNaN(value)) return;
    debouncedPageChange(value);
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    localStorage.setItem("completedPoaPageSize", newSize);
    setPageNumber(1);
    setInputPageNumber("1");
    // Apply current sorting when changing page size
    const { field, order } = sortCondition;
    const url = `${API_URL}personas/entity-poa/?is_complete=true&page_size=${newSize}`;

    if (field) {
      getCompletedPoaEntitiesHandler(`${url}&ordering=${order}${field}`);
    } else {
      getCompletedPoaEntitiesHandler(url);
    }
  };

  const fetchNextPage = () => {
    if (completedNextPageUrl) {
      const nextPage = Number(pageNumber) + 1;
      setPageNumber(nextPage);
      setInputPageNumber(nextPage.toString());
      getCompletedPoaEntitiesHandler(completedNextPageUrl);
    }
  };

  const fetchPreviousPage = () => {
    if (completedPreviousPageUrl) {
      const previousPage = Number(pageNumber) - 1;
      setPageNumber(previousPage);
      setInputPageNumber(previousPage.toString());
      getCompletedPoaEntitiesHandler(completedPreviousPageUrl);
    }
  };

  const handleSorting = (field) => {
    setSortCondition((prev) => {
      let order = "";

      if (prev.field === field) {
        order = prev.order === "" ? "-" : "";
      }

      return {
        field,
        order,
      };
    });
  };

  useEffect(() => {
    const completedPoaSort = localStorage.getItem(`completedPoaSort`);
    const savedPageSize = localStorage.getItem("completedPoaPageSize");

    if (savedPageSize) {
      setPageSize(Number(savedPageSize));
    }

    if (completedPoaSort) {
      setSortCondition(extractOrdering(completedPoaSort));
    } else {
      // If no sort condition, make initial API call
      const url = `${API_URL}personas/entity-poa/?is_complete=true&page_size=${
        savedPageSize || pageSize
      }`;
      getCompletedPoaEntitiesHandler(url);
    }
  }, []);

  useEffect(() => {
    const { field, order } = sortCondition;
    const currentPageSize =
      localStorage.getItem("completedPoaPageSize") || pageSize;

    let url = `${API_URL}personas/entity-poa/?is_complete=true&page_size=${currentPageSize}`;

    if (field) {
      url += `&ordering=${order}${field}`;
      localStorage.setItem(`completedPoaSort`, url);
      setInputPageNumber("1");
      getCompletedPoaEntitiesHandler(url);
    }
  }, [sortCondition]);

  return (
    <>
      {loading && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
          style={{
            zIndex: "9999",
          }}
        >
          <TableLaoding />
        </div>
      )}
      {completedPoaEntities.length > 0 ? (
        <Table
          columns={columns}
          data={completedPoaEntities}
          pageSize={completedPageSize}
          totalPages={totalPages}
          inputPageNumber={inputPageNumber}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
          previousPageUrl={completedPreviousPageUrl}
          nextPageUrl={completedNextPageUrl}
          onPreviousPage={fetchPreviousPage}
          onNextPage={fetchNextPage}
          onSort={handleSorting}
        />
      ) : (
        <div className="d-flex justify-content-center pt-2">
          <TitleText restStyle="fs-5">
            No List of POAs which have been completed.
          </TitleText>
        </div>
      )}
    </>
  );
}
