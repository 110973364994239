export const NavigationBar = (props) => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const {
    backText = "Back",
    nextText = "Next",
    backVisible = true,
    nextVisible = true,
    backFunc = () => {},
    nextFunc = () => {},
    cancelVisible = false,
    cancelFunc = () => {},
  } = props;
  return (
    <div
      style={{
        borderTop: "2px solid #CECECE",
      }}
      className="d-flex justify-content-between px-4 "
    >
      <div>
        <div>
          {backVisible && (
            <button
            style={{
              backgroundColor: themeColor,
              borderColor: themeColor,
            }}
              type="button"
              className="btn btn-danger px-5  my-2"
              onClick={backFunc}
            >
              {backText}
            </button>
          )}
        </div>
      </div>

      <div>
      
          {cancelVisible && (
            <button
            style={{
              color: themeColor,
              borderColor: themeColor,
              backgroundColor: "white",
            }}
              type="button"
              className="btn  px-5 mx-4 my-2"
              onClick={cancelFunc}
            >
              Cancel
            </button>
          )}
     
        {nextVisible && (
          <button
          style={{
            backgroundColor: themeColor,
            borderColor: themeColor,
          }}
            type="button"
            className="btn btn-danger px-5  my-2"
            onClick={nextFunc}
          >
            {nextText}
          </button>
        )}
      </div>
    </div>
  );
};
