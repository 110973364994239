import { isEqual } from "lodash";

import { AccordionItem } from "./AccordionItem";

export const Accordion = (props) => {
  const { faqType, item, setFaqType } = props;

  const handleFaq = () => {
    setFaqType((prev) => (isEqual(prev, item.type) ? "" : item.type));
  };

  return (
    <div
      style={{ backgroundColor: "#e5e7eb" }}
      className={`border border-2 rounded overflow-hidden mb-3`}
    >
      <AccordionItem
        header={item.header}
        type={item.type}
        faqType={faqType}
        handleFaq={handleFaq}
      />
      {
        item.type === faqType && (
          // map(item.faqItem, ({ question, answer }, idx) => (
          <div
            key={item.type}
            className={`${faqType && "px-4 pb-4 text-muted "}`}
          >
            <div className="w-100 text-muted ">{item.answer}</div>
          </div>
        )
        // ))
      }
    </div>
  );
};
