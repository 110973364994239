import React, { createContext, useState, useContext } from "react";

// Create the context
const QuestionnaireContext = createContext();

// Create a provider component
export const QuestionnaireProvider = ({ children }) => {
  // Define state that you want to share
  const [autoPopulatedValues, setAutoPopulatedValues] = useState({}); // For blue auto-populated values
  

  return (
    <QuestionnaireContext.Provider
      value={{
        autoPopulatedValues,
        setAutoPopulatedValues,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

// Custom hook to use the QuestionnaireContext
export const useQuestionnaire = () => useContext(QuestionnaireContext);

export default QuestionnaireContext;
