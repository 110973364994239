import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import datePicker from '../../../assets/images/date-picker.svg';
import 'react-datepicker/dist/react-datepicker.css';

export const MyDatePicker = ({
	label = 'Select Date',
	onChange = () => {},
	isRequired = true,
}) => {
	const [startDate, setStartDate] = useState(new Date());
	return (
		<div
			className="w-100 h-100 d-flex flex-column gap-2 justify-content-between"
			style={{
				borderBottom: '2px solid #ced4da',
			}}
		>
			<div>
				<label
					style={{
						color: '#1E3542',
						fontSize: '17px',
						fontStyle: 'normal',
						fontWeight: '500',
						lineHeight: '18px',
					}}
				>
					{label}
					{isRequired && <span className="text-danger">*</span>}
				</label>
			</div>
			<div className="d-flex w-100">
				<DatePicker
					className="w-100 border-0"
					selected={startDate}
					onChange={(date) => setStartDate(date)}
					wrapperClassName="w-100"
				/>
				<img
					src={datePicker}
					alt="date-picker"
				/>
			</div>
		</div>
	);
};
