import React, { useEffect, useState } from "react";
import validator from "validator";
import { apiFetchWrapper } from "../utils/axiosApiWrapper";
import { TextInput } from "./common";
import { getRedirectUrl } from "../utils/handleSSO";

const SSOLogin = () => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const publicDomainRegex = new RegExp(
    /@(?!gmail\.com|yahoo\.com|outlook\.com|hotmail\.com|icloud\.com|aol\.com|mail\.com|protonmail\.com|zoho\.com|yandex\.com)[\w.-]+$/i
  );
  const parsedTenantsInfo = JSON.parse(
    localStorage.getItem("tenantsInfo") || "{}"
  );
  const user = localStorage.getItem("user");

  const [email, setEmail] = useState(parsedTenantsInfo.email ?? "");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!isLoading)
      try {
        setIsLoading(true);
        if (!validator.isEmail(email)) {
          setError("Invalid email address");
          return;
        }

        if (!publicDomainRegex.test(email)) {
          setError("It looks like you're signing in with a public email.");
          return;
        }
        setError(null);
        //RUN API REQUEST

        const tenantsInfo = await apiFetchWrapper.post(
          "tenants/tenant-version-info/",
          {
            email,
          }
        );
        const stringifiedData = JSON.stringify({
          ...tenantsInfo.data,
          email,
        });
        localStorage.setItem("tenantsInfo", stringifiedData);
        // REDIRECT TO SSO
        window.location.href = getRedirectUrl();
      } catch (err) {
        console.error("error", err);
        if (err?.response?.status === 404) {
          setError(
            `Invalid domain, use your work email or contact ${process.env.REACT_APP_PROJECT_NAME} at ${process.env.REACT_APP_OFFICIAL_MAIL}.`
          );
        } else
          setError(
            JSON.stringify(err?.response?.data) ?? "Invalid email address"
          );
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    if (user) window.location.href = "/";
  }, [user]);

  return (
    <div
      style={{ height: "100vh", padding: "0 13px" }}
      className="d-flex  justify-content-center align-items-center"
    >
      <div
        className="card"
        style={{ maxWidth: "500px", padding: "30px", width: "100%" }}
      >
        <TextInput
          isRequired={true}
          description="Enter email address"
          value={email.trim()}
          name="email"
          type="email"
          onChange={(name, value) => setEmail(value)}
          placeholder="abc@company.com"
          error={error}
          onKeyEnter={() => handleSubmit()}
        />
        <div className="d-flex  justify-content-center align-items-center">
          <button
            style={{
              backgroundColor: themeColor,
              borderColor: themeColor,
              color: "white",
              width: "150px",
            }}
            onClick={handleSubmit}
            className="btn  btn-block mt-4"
          >
            <span>Login</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SSOLogin;
