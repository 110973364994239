const getQueryParamsFromObj = (filterData) => {
  // Initialize an empty array to store query parameters
  const params = [];

  // Iterate over the filterData object's keys
  for (const key in filterData) {
    if (filterData[key]) {
      // Check if the value is not empty
      // Encode the key and value, and add to the params array
      params.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(filterData[key])}`
      );
    }
  }

  // Join the parameters with '&' to form the query string
  return `?${params.join("&")}`;
};

export default getQueryParamsFromObj;
