import { apiFetchWrapper } from "../utils/axiosApiWrapper";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACK_END_URL;

const getFormField = (payload) => {
  return apiFetchWrapper.get(
    API_URL +
      `compliance/forms/?compliance_type=MSW-A1,A1,COC&country=${payload.homeCountry}&detail=true`,
    {
      headers: authHeader(),
    }
  );
};
const submitFormFields = (payload) => {
  // const payloadData=
  return apiFetchWrapper.patch(
    API_URL + `compliance/persona-compliance-details/bulk-update/`,
    payload,
    {
      headers: authHeader(),
    }
  );
};
const getFormPersonaDetails = (payload) => {
  // const payloadData=
  return apiFetchWrapper.get(
    API_URL +
      `compliance/compliance-form-assumptions/?compliance_type=MSW-A1,A1,COC&country=${payload.country_code}`,
    {
      headers: authHeader(),
    }
  );
};
const getEntityComplianceDetails = (payload) => {
  // const payloadData=
  return apiFetchWrapper.get(
    API_URL +
      `compliance/persona-compliance-details/?compliance_type=MSW-A1,A1,COC&persona=${payload.uuid}`,
    {
      headers: authHeader(),
    }
  );
};
const questionnairService = {
  getFormField,
  getFormPersonaDetails,
  submitFormFields,
  getEntityComplianceDetails,
};

export default questionnairService;
