import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Steps from "./Steps/steps";
import { useDispatch, useSelector } from "react-redux";
import { FieldType } from "../../../common/constants";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { useFormik } from "formik";
import { generateA1sValidationSchema } from "../../../common/helper/generateA1sValidationSchema.js";
import { getDateDisplayFormat } from "../../../common/helper/formateDate.js";
import { GroupContainer } from "./GroupContainer/index.js";
import { NavigationWrapper } from "./NavigateBtn/index.js";
import { useLocation } from "react-router-dom";
import {
  getFormFieldData,
  submitFormFieldData,
  updateShowError,
  updateValues,
} from "../../../slices/questionnaire.js";
import dashboardService from "../../../services/dashboard.service.js";
import { Loader } from "../../../components/common/Loading/loading.js";

import { TitleText } from "../../../components/TitleText/index.js";
import { useQuestionnaire } from "./context.jsx";

const Questionnaire = () => {
  const {
    questionnaire,
    flatedFields,
    employerDetail,
    storedValues,
    researchEmail,
    homeCountry,
    hostCountry,
    currentSelectedGroup,
    loading,
    complianceLoading,
    filterdEmployerMSWQuestionaire,
    filterdEmployerA1Questionaire,
    flatenFilterdEmployerCOCQuestionaire,
  } = useSelector((state) => state.questionnaire);
  const { countryObject } = useCountryList();
  const { setAutoPopulatedValues } = useQuestionnaire();

  // This ref prevents the field from being reset on step change, the reset occurs in each field's component with a statement like the one below:
  // if(initializable) onChange(name, ""): This "initializable" logic is now removed from the codebase, but we'll still keep this ref for now. Incase its tied to another thing, will have to check it out sometime later.
  const fieldResetFlagRef = useRef(true);

  const [personaComplianceValues, setPersonaComplianceValues] = useState([]);

  const { personaEntities, loading: personaEntitiesLoading } = useSelector(
    (state) => state.dashboard
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const [item, setItem] = useState({});
  const [address, setAddress] = useState({});
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);
  const [runSetDefaultValues, setRunSetDefaultValues] = useState(false);
  const itemEntity = location.state?.item;

  const updatePersona = useCallback(async () => {
    const fromModal = location.state?.fromModal;
    if (!fromModal) {
      setItem(location.state?.item);
    }
    const savedAddress = location.state?.address;
    setAddress(savedAddress || {});
    if (fromModal) {
      const item = location.state?.item;

      const persona = personaEntities?.find((entity) => {
        return (
          entity.persona === item.uuid && entity.compliance_type === "MSW-A1"
        );
      });
      if (!persona) {
        return;
      }

      setItem(persona);
    }
  }, [location, personaEntities]);

  useEffect(() => {
    updatePersona();
  }, [updatePersona]);

  const [stepsGroupObj, setStepsGroupObj] = useState({});
  const validationSchema = useMemo(
    () => generateA1sValidationSchema(flatedFields),
    [flatedFields]
  );
  const initialValues = {};

  const formikData = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  });

  const { values, errors, setFieldValue, setErrors, validateForm } = formikData;

  const getComplianceDetails = useCallback(async () => {
    const personaAnswer = await dashboardService.getPersonaEntityAnswer(
      item.uuid
    );
    if (!personaAnswer || personaAnswer?.error) return;
    setPersonaComplianceValues([...personaAnswer.data.results]);

    let personaFields = [];

    personaAnswer.data.results?.forEach((_item) => {
      const isValueArray = Array.isArray(_item.fields);
      if (isValueArray) {
        personaFields = [...personaFields, ..._item.fields];
      }
    });

    const names = {};
    if (personaFields.length) {
      const storingData = {};
      personaFields?.forEach((_field) => {
        const { key, value, auto_populated } = _field;
        setFieldValue(key, value);
        storingData[key] = value;
        if (auto_populated) names[key] = value;
      });
      if (Object.keys(storingData)?.length) {
        dispatch(updateValues({ values: storingData }));
      }
      setAutoPopulatedValues(names);
    }
  }, [item, setFieldValue, dispatch]);

  const getFormData = useCallback(async () => {
    if (!item?.country_code) return;
    await getComplianceDetails();
    dispatch(
      getFormFieldData({
        homeCountry: item?.country_code,
        type: item?.compliance_type_name,
        entity: item,
      })
    ).then(() => {
      setTimeout(() => {
        setErrors({});
      }, 1000);
    });
  }, [dispatch, item, getComplianceDetails, setErrors]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  useEffect(() => {
    if (flatedFields && questionnaire) {
      const groupsArray = questionnaire
        ?.filter(
          (field) =>
            field.type !== undefined && field.type !== FieldType.DATE_DISABLED
        )
        .map((field) => field.group?.trim());

      const uniqueGroupsArray = groupsArray.filter(
        (group, index, self) => self.indexOf(group) === index
      );

      const groupObject = {};
      uniqueGroupsArray.forEach((group, index) => {
        groupObject[index + 1] = group;
      });
      setStepsGroupObj(groupObject);
    }
  }, [flatedFields, questionnaire]);

  const updateDateSamePastFields = useCallback(
    (name, value) => {
      const allCommonFields = flatedFields.filter(
        (field) =>
          field.name !== name && field.type === FieldType.DATE_PAST_SAME
      );

      if (allCommonFields.length) {
        setTimeout(() => {
          allCommonFields.forEach((commonField) => {
            if (!values[commonField?.name]?.length) {
              setFieldValue(commonField.name, value);
            }
          });
        }, 400);
      }
    },
    [flatedFields, setFieldValue, values]
  );

  const updateFieldValue = useCallback(
    async (name, value, type) => {
      if (fieldResetFlagRef.current) {
        dispatch(updateShowError({ flag: true }));
        setFieldValue(name, value);

        // UPDATE FIELDS WITH DATE_SAME_PAST TYPE
        if (type === FieldType.DATE_PAST_SAME)
          updateDateSamePastFields(name, value);
      }
    },
    [setFieldValue, updateDateSamePastFields]
  );

  useEffect(() => {
    fieldResetFlagRef.current = true;
  }, [currentSelectedGroup]);

  const updatePersonaAnswers = useCallback(async () => {
    if (!item?.uuid && flatedFields) {
      return;
    }
    if (
      !personaComplianceValues.length ||
      !filterdEmployerMSWQuestionaire?.length ||
      !filterdEmployerA1Questionaire?.length
    ) {
      return;
    }
    if (isAddressUpdated) return;
    if (Object.keys(address)?.length) {
      let a1Payload = personaComplianceValues.find(
        (_item) => _item.compliance_type === "A1"
      ) || {
        uuid: item.uuid,
        fields: {},
        is_complete: false,
      };
      let mswPayload = personaComplianceValues.find(
        (_item) => _item.compliance_type === "MSW-A1"
      ) || {
        uuid: item.uuid,
        fields: {},
        is_complete: false,
      };
      let cocPayload = personaComplianceValues.find(
        (_item) => _item.compliance_type === "COC"
      ) || {
        uuid: item.uuid,
        fields: {},
        is_complete: false,
      };

      const a1Fields = [];
      const mswFields = [];
      const cocFields = [];

      filterdEmployerMSWQuestionaire.forEach((_item) => {
        if (address[_item.type]) {
          mswFields.push({
            key: _item.name,
            value: address[_item.type],
            auto_populated: true,
          });
        }
      });
      filterdEmployerA1Questionaire.forEach((_item) => {
        if (address[_item.type]) {
          a1Fields.push({
            key: _item.name,
            value: address[_item.type],
            auto_populated: true,
          });
        }
      });

      flatenFilterdEmployerCOCQuestionaire.forEach((_item) => {
        if (address[_item.type]) {
          cocFields.push({
            key: _item.name,
            value: address[_item.type],
            auto_populated: true,
          });
        }
      });
      mswPayload = {
        uuid: mswPayload.uuid,
        is_complete: false,
        fields: mswFields,
      };
      a1Payload = {
        uuid: a1Payload.uuid,
        is_complete: false,
        fields: a1Fields,
      };
      cocPayload = {
        uuid: cocPayload.uuid,
        is_complete: false,
        fields: cocFields,
      };

      dispatch(submitFormFieldData([mswPayload, a1Payload, cocPayload])).then(
        () => {
          getComplianceDetails();
        }
      );
      setIsAddressUpdated(true);
    }
  }, [
    dispatch,
    item,
    flatedFields,
    address,
    getComplianceDetails,
    personaComplianceValues,
    filterdEmployerA1Questionaire,
    filterdEmployerMSWQuestionaire,
    isAddressUpdated,
    flatenFilterdEmployerCOCQuestionaire,
  ]);

  useEffect(() => {
    if (flatedFields?.length) updatePersonaAnswers();
  }, [updatePersonaAnswers, flatedFields]);

  // Populate fields that have a `default_value` attribute with their default values. This should run only once.
  useEffect(() => {
    if (runSetDefaultValues && fieldResetFlagRef.current) {
      flatedFields.forEach((_field) => {
        const hasDefaultValue =
          _field.default_value != null &&
          String(_field.default_value).trim().length > 0;
        if (
          hasDefaultValue &&
          (values[_field.name] === "" || !values.hasOwnProperty(_field.name))
        )
          updateFieldValue(_field.name, _field?.default_value);
      });
      setRunSetDefaultValues(false); // Set to false
    }
  }, [
    flatedFields,
    runSetDefaultValues,
    values,
    updateFieldValue,
    fieldResetFlagRef,
  ]);

  // Reinitialize Formik initial values to add in fields keys with empty/default values, and makes setting "isComplete" flag easier.
  useEffect(() => {
    if (flatedFields.length)
      for (const field of flatedFields) {
        if (field.type === FieldType.DATE_DISABLED) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : getDateDisplayFormat(new Date())
          );
        } else if (field.type === FieldType.INTEGER) {
          setFieldValue(
            field.name,
            storedValues.hasOwnProperty(field.name)
              ? storedValues[field.name]
              : field.extra_validations?.regex
              ? ""
              : ""
          );
        } else if (field.type === FieldType.BOOLEAN) {
          setFieldValue(
            field.name,
            storedValues.hasOwnProperty(field.name)
              ? storedValues[field.name]
              : ""
          );
        } else if (field.type === FieldType.USER_EMAIL) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : researchEmail
              ? researchEmail
              : ""
          );
        } else if (field.type === FieldType.NATIONALITY) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : countryObject[homeCountry] ?? homeCountry
          );
        } else if (
          field.type === FieldType.HOST_COUNTRY ||
          field.type === FieldType.COUNTRY
        ) {
          const countryType = homeCountry;

          setFieldValue(
            field.name,
            countryObject[countryType]
              ? countryObject[countryType]
              : storedValues[field.name]
              ? storedValues[field.name]
              : countryType
              ? countryType
              : ""
          );
        } else if (field.type === FieldType?.HOST_COUNTRY_CODE) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : hostCountry
              ? hostCountry
              : ""
          );
        } else if (field?.type === FieldType.ADD_DESTINATION) {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : false
          );
        } else {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : ""
          );
        }
        if (
          field.persona === FieldType.ASSUMPTION &&
          employerDetail.hasOwnProperty(field.name)
        ) {
          setFieldValue(field.name, employerDetail[field.name]);
        }
      }
  }, [flatedFields]);

  useEffect(() => {
    if (!loading && !personaEntitiesLoading && !complianceLoading)
      setRunSetDefaultValues(true);
  }, [loading, personaEntitiesLoading, complianceLoading]);

  if (loading || personaEntitiesLoading || complianceLoading) return <Loader />;

  if (!questionnaire?.length)
    return (
      <div className="d-flex justify-content-center py-5">
        <TitleText restStyle="fs-5">No Employer question to show.</TitleText>
      </div>
    );
  return (
    <>
      {Object.keys(stepsGroupObj)?.length > 1 ? (
        <Steps
          currentSelectedGroup={currentSelectedGroup}
          stepsGroupObj={stepsGroupObj}
        />
      ) : null}
      <GroupContainer
        countryName={itemEntity?.country_name || item?.country_name}
        personaName={itemEntity?.name || item?.name}
        fields={questionnaire}
        selectedGroupName={stepsGroupObj[currentSelectedGroup]}
        values={values}
        errors={errors}
        setFieldValue={updateFieldValue}
        employerDetail={employerDetail}
      />
      <NavigationWrapper
        values={values}
        stepsGroupObj={stepsGroupObj}
        fieldResetFlagRef={fieldResetFlagRef}
        currentSelectedGroup={currentSelectedGroup}
        item={item}
        personaComplianceValues={personaComplianceValues}
        validateForm={validateForm}
        setErrors={setErrors}
      />
    </>
  );
};

export default Questionnaire;
