import { apiFetchWrapper } from "../utils/axiosApiWrapper";
import { getFileContentType } from "../utils/fileConversions";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACK_END_URL;

const getPoaEntities = (url, is_complete) => {
  const apiUrl = url
    ? url
    : API_URL + `personas/entity-poa/?is_complete=${is_complete}`;

  return apiFetchWrapper.get(apiUrl, {
    headers: authHeader(),
  });
};
const updatePoaEntities = (url, body) => {
  const apiUrl = url ? url : API_URL + `personas/entity-poa/`;

  return apiFetchWrapper.put(apiUrl, body, true, false);
};

const uploadDocumentUrl = (payload) => {
  const apiUrl = API_URL + `compliance/document_upload_url/`;

  return apiFetchWrapper.post(apiUrl, payload, {
    headers: authHeader(),
  });
};
const uploadDocument = async (url, payload) => {
  const apiUrl = url ? url : API_URL + `compliance/document_upload_url/`;

  return apiFetchWrapper.put(apiUrl, payload, true, true);
};

const powerOfAttorneyService = {
  getPoaEntities,
  uploadDocumentUrl,
  uploadDocument,
  updatePoaEntities,
};

export default powerOfAttorneyService;
