import React from "react";

export const Text = ({ children, restStyle = "" }) => {
  const divStyle = {
    color: "#758283",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
  };
  return (
    <div style={divStyle} className={`d-flex  ${restStyle}`}>
      {children}
    </div>
  );
};
