import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SignatureCanvas from "react-signature-canvas";

import { updateSignatures } from "../../../slices/questionnaire";
import { HelpText } from "../HelpText";
import { TitleText } from "../../TitleText";

export const Signature = React.memo(function Signature(props) {
  const {
    description = "",
    isRequired = false,
    value = "",
    error = "",
    showError = false,
    helpText = "",
    name = "",
    onChange = () => {},
  } = props;

  const sigPad = useRef({});
  const sigCanvas = useRef(null);
  const [isTouched, setIsTouched] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [_value, setValue] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!error && (isTouched || showError)) setDisplayError(true);
    else setDisplayError(false);
  }, [error, isTouched, showError]);

  useEffect(() => {
    if (value) sigCanvas.current = value;
  }, []);

  const saveSignature = useCallback(() => {
    const signURL = sigCanvas.current?.toDataURL();

    if (signURL && visible) onChange(name, signURL);
    else setIsTouched(true);
  }, [name, onChange, visible]);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      sigCanvas.current = node;
      sigPad.current = node.getSignaturePad();
    }
  }, []);

  const clearInput = () => {
    if (sigPad.current) {
      if (!_value) sigPad?.current?.clear();
      onChange(name, "");
      setValue();
      dispatch(updateSignatures({ [name]: "" }));
      setVisible(false);
      setIsTouched(true);
    }
  };
  // const simpleText = 14

  return (
    <div className={`flex flex-col w-full relative justify-between h-full`}>
      <div className="d-flex align-items-start mb-2">
        <label htmlFor={name} className="d-flex">
          <TitleText
            children={`${description}${
              !isRequired && description ? " (optional)" : ""
            }`}
          />
          {/* {isRequired && <Sterik />} */}
        </label>
        {helpText && <HelpText helpText={helpText} className="pl-2" />}
      </div>
      <div className="w-100">
        {!_value && (
          <SignatureCanvas
            ref={measuredRef}
            onBegin={() => setVisible(true)}
            onEnd={() => {
              setIsTouched(false);
              const signURL = sigCanvas.current?.toDataURL();
              onChange(name, signURL);
              dispatch(
                updateSignatures({
                  [name]: sigCanvas.current?.toDataURL() || "",
                })
              );
            }}
            penColor="blue"
            canvasProps={{
              style: {
                border: "2px solid #CECECE",
              },
              height: 200,
              className: "col-10 rounded  mt-3",
            }}
          />
        )}

        {_value && (
          <img
            height={200}
            src={value}
            className="col-10 rounded border mt-3"
            alt=""
          />
        )}
      </div>
      {/* 
      {!!value && (
        <div
          className={`focus-visible:outline-none text-danger   h-[15px] w-full text-[12px] sm:text-[14px] lg:text-[16px] leading-[15px] sm:leading-[17px] lg:leading-[19px] visible `}
        >
          Your signature has been saved
        </div>
      )} */}
      {error && (
        <div
          className={` focus-visible:outline-none  text-danger h-[15px] w-full text-[12px] sm:text-[14px] lg:text-[16px] leading-[15px] sm:leading-[17px] lg:leading-[19px] ${
            displayError ? "visible" : "invisible"
          }`}
        >
          {error}
        </div>
      )}
      <div className="col-12 d-flex justify-content-between">
        <div className="d-flex justify-content-between">
          <button
            style={{
              border: "1px solid #D3C0C0",
              width: "150px",
              ...(!value && { visibility: "hidden" }),
            }}
            className="btn text-danger mt-2 mr-3"
            onClick={clearInput}
          >
            Clear
          </button>
        </div>
        <div className="d-flex justify-content-end">
          {/* {!value && (
            <button
              className="btn btn-danger mt-5 w-150 max-md:w-130"
              onClick={saveSignature}
            >
              Save
            </button>
          )} */}
          {/* {value && (
            <button
              className="btn   btn-danger mt-5 w-150 max-md:w-130"
              onClick={clearInput}
            >
              Redo
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
});
