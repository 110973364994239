import React, { useState } from "react";
import { Heading, NormalText } from "../../components/common";
import { faqData, faqsForA11, faqsForA12 } from "../../common/constants";
import { Accordion } from "./Accordion/Index";

export const Faq = () => {
  const [faqType, setFaqType] = useState(faqData.type);

  return (
    <div className="container-fluid px-5 py-5 auto-scroll-y">
      <Heading>Frequently Asked Questions</Heading>

      <div className="pt-5" style={{ maxWidth: "50rem" }}>
        <div className="row">
          <div className="flex flex-col w-full gap-5">
            {faqsForA11?.map((item, i) => {
              return (
                <Accordion
                  key={i}
                  faqType={faqType}
                  setFaqType={setFaqType}
                  item={item}
                />
              );
            })}
          </div>
          <div className="flex flex-col w-full gap-5">
            {faqsForA12?.map((item, i) => {
              return (
                <Accordion
                  key={i}
                  faqType={faqType}
                  setFaqType={setFaqType}
                  item={item}
                />
              );
            })}
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
