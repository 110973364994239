import React from "react";

const ProgressLoading = ({ percentage, clearLoderHandler }) => {
  return (
    <div className="position-fixed top-0 start-0 d-flex flex-column align-items-center justify-content-center w-100 h-100 overflow-hidden bg-black bg-opacity-50 loader-custom-class">
      <div className="circular-progress">
        <svg className="circular-progress-svg" viewBox="0 0 36 36">
          <path
            className="circular-progress-bg"
            d="M18 1.0845A16.9155 16.9155 0 0 0 1.0845 18 16.9155 16.9155 0 0 0 18 34.9155 16.9155 16.9155 0 0 0 34.9155 18 16.9155 16.9155 0 0 0 18 1.0845"
          />
          <path
            className="circular-progress-fg"
            strokeDasharray={`${percentage} 100`}
            d="M18 1.0845A16.9155 16.9155 0 0 0 1.0845 18 16.9155 16.9155 0 0 0 18 34.9155 16.9155 16.9155 0 0 0 34.9155 18 16.9155 16.9155 0 0 0 18 1.0845"
          />
        </svg>
        <div className="circular-progress-text">{percentage}%</div>
      </div>

      <div
        style={{
          border: "1px solid #a1a1a1",
          padding: "10px",
          borderRadius: "15px",
          fontSize: "20px",
        }}
        onClick={clearLoderHandler}
        className="mt-3 text-start text-white cursor-pointer"
      >
        Cancel
      </div>

      <div className="mt-3 text-start text-white ping-animation">
        Searching...
      </div>
    </div>
  );
};

export default ProgressLoading;
