import React, { useRef } from "react";
import { Tooltip } from "react-tooltip";
import icon from "../../../assets/images/help.svg";
import Linkify from "linkify-react";
import styles from "./styles.module.css";
import parse from "html-react-parser";

export const HelpText = React.memo(
  ({
    helpText = "",
    className = "",
    content,
    isIconeHide,
    noPadding,
    contentDivClassNames = "",
  }) => {
    const id = Math.random().toString(36).substr(2, 9);
    const toolTipFrameRef = useRef(null);
    const formatted_text = helpText.replace(/\n/g, "<br/>");

    return (
      <div
        style={noPadding ? {} : { marginTop: "-3px", paddingLeft: "5px" }}
        className={className}
      >
        <div
          className={contentDivClassNames}
          data-for={id ?? "my-tooltip"}
          data-tooltip-id={id ?? `my-tooltip`}
          data-tooltip-place="bottom"
          data-tooltip-offset={20}
        >
          {content}
          {isIconeHide !== true && (
            <img
              className="cursor-pointer"
              src={icon}
              alt="help"
              width={22}
              height={25}
            />
          )}
        </div>
        <span ref={toolTipFrameRef}>
          <Tooltip
            id={id ?? "my-tooltip"}
            className={`max-w-[325px] min-w-25`}
            clickable
            style={{
              zIndex: 9999,
              minWidth: "250px",
              maxWidth: "500px",
              maxHeight: "50vh",
              overflow: "auto",
              width: "fit-content",
              fontSize: "1rem",
              wordBreak: "break-word",
              letterSpacing: "0.7px",
              backgroundColor: "#000",
            }}
          >
            <Linkify
              options={{
                target: "_blank",
                className: `${styles.helpTextUrl} ${styles.htmlContent}`,
              }}
            >
              {parse(formatted_text)}
            </Linkify>
          </Tooltip>
        </span>
      </div>
    );
  }
);
