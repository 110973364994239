const addIcone = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.99707C13 3.68457 10.3125 0.99707 7 0.99707C3.6875 0.99707 1 3.68457 1 6.99707C1 10.3096 3.6875 12.9971 7 12.9971C10.3125 12.9971 13 10.3096 13 6.99707Z"
        stroke={process.env.REACT_APP_LIGHT_PRIMARY}
        strokeWidth="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M9.5 6.99805H4.5M7 4.49805V9.49805V4.49805Z"
        stroke={process.env.REACT_APP_LIGHT_PRIMARY}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default addIcone;
