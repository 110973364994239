import { saveAs } from "file-saver";
import { fileContentType } from "../common/constants";

const getExtensionFromContentType = (contentType) => {
  // Extract the file extension from the content type string
  const extensionMatch = contentType.match(/\/([a-zA-Z0-9]+)$/);

  // Check if the extension match is found
  if (extensionMatch && extensionMatch[1]) {
    return "." + extensionMatch[1];
  } else {
    const contentTypeMap = Object.entries(fileContentType).reduce(
      (acc, [ext, type]) => {
        acc[type] = ext;
        return acc;
      },
      {}
    );
    return contentTypeMap[contentType] ? "." + contentTypeMap[contentType] : "";
  }
};

export const handleDownload = async (url, name) => {
  try {
    const response = await fetch(url);
    const extension = getExtensionFromContentType(
      response.headers.get("content-type")
    );

    const fileName = name + extension;

    const blob = await response.blob();

    saveAs(blob, fileName);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
