import React from "react";
import uiVars from "../../../assets/scss/_variables.scss";
const getColor = (clr) => {
  let textColor = "";
  switch (clr) {
    case "theme":
      textColor = process.env.REACT_APP_LIGHT_PRIMARY;
      break;
    case "red":
      textColor = uiVars.redColor;
      break;
    case "grey":
      textColor = uiVars.greyColor;
      break;
    case "primary":
      textColor = uiVars.primary;
      break;
    case "white":
      textColor = "white";
      break;
    default:
      textColor = uiVars.blackColor;
      break;
  }
  return textColor;
};

export const Heading = (props) => {
  const { children, color } = props;
  let textColor = getColor(color);
  return (
    <span
      style={{
        fontSize: uiVars.headingSize,
        fontWeight: uiVars.headingWeight,
        ...props,
        color: textColor,
      }}
    >
      {children}
    </span>
  );
};

export const NormalText = (props) => {
  const { children, color, onClick = () => {} } = props;
  let textColor = getColor(color);
  return (
    <span
      onClick={onClick}
      style={{
        fontSize: uiVars.normalSize,
        fontWeight: uiVars.normalWeight,
        ...props,
        color: textColor,
      }}
    >
      {children}
    </span>
  );
};

export const LargeText = (props) => {
  const { children, color } = props;
  let textColor = getColor(color);
  return (
    <span
      style={{
        fontSize: uiVars.largeSize,
        fontWeight: uiVars.largeWeight,
        ...props,
        color: textColor,
      }}
    >
      {children}
    </span>
  );
};
export const NumberFormate = (props) => {
  const { children, color } = props;
  let textColor = getColor(color);
  function formatNumberWithCommas(number) {
    if (typeof number !== "number" || isNaN(number)) return number;

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <span
      style={{
        fontSize: uiVars.largeSize,
        fontWeight: uiVars.largeWeight,
        ...props,
        color: textColor,
      }}
    >
      {formatNumberWithCommas(children)}{" "}
    </span>
  );
};
