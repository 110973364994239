import React, { useCallback, useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import useCountryList from "../../../../assets/shared/hooks/useCountryList";
import {
  updateFilter,
  updateSelectedRoute,
} from "../../../../slices/filledApplications";
import { primaryColor } from "../../../../common/env";
import { useAnalyticsFilter } from "../analyticsContext";

const DoubleBarGraph = ({ homeCountrySelected, hostCountrySelected }) => {
  const dispatch = useDispatch();
  const { getCountryName } = useCountryList();
  const { filterData } = useAnalyticsFilter();
  const { home_country, host_country, ...filteredData } = filterData;
  const { mostCommonRoutes, selectedRoute } = useSelector(
    (state) => state.filledApplications
  );
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const selectedRouteRef = useRef(selectedRoute);
  const [chartReady, setChartReady] = useState(false);

  const graphDataStructureHandler = useCallback(
    (routes) => {
      const categories =
        routes?.map((route) => {
          const country = route?.country;
          return `${country ? getCountryName(country) : ""}`;
        }) || [];
      setOptions({
        chart: {
          type: "bar",
          events: {
            dataPointSelection: (
              event,
              chartContext,
              { seriesIndex, dataPointIndex, w }
            ) => {
              const clickedData =
                w.config.series[seriesIndex].data[dataPointIndex];
              const newSelectedRoute = routes?.find(
                (_item) => _item.country === clickedData.country
              );

              if (clickedData.country === selectedRouteRef.current?.country) {
                dispatch(updateSelectedRoute({}));
                dispatch(updateFilter(filterData));
              } else {
                dispatch(updateSelectedRoute(newSelectedRoute));

                dispatch(
                  updateFilter({
                    ...filteredData,
                    home_country: clickedData.country,
                  })
                );
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          title: {
            text: "No. of A1s",
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
        colors: [primaryColor, "#000"],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString();
            },
          },
        },
      });

      const series = [
        {
          name: "Filed Applications",
          data:
            routes?.map((route) => ({
              x: route?.country ? getCountryName(route.country) : "",
              y: route.filed_applications_count.toLocaleString(),
              country: route.country,
            })) || [],
        },
        {
          name: "Approved Applications",
          data:
            routes?.map((route) => ({
              x: route?.country ? getCountryName(route.country) : "",
              y: route.approved_applications_count.toLocaleString(),
              country: route.country,
            })) || [],
        },
      ];

      setSeries(series);
    },
    [getCountryName, dispatch, selectedRoute.country]
  );

  useEffect(() => {
    graphDataStructureHandler(mostCommonRoutes);
  }, [mostCommonRoutes, graphDataStructureHandler]);

  // Update the ref whenever selectedRoute changes
  useEffect(() => {
    selectedRouteRef.current = selectedRoute;
  }, [selectedRoute]);

  useEffect(() => {
    if (homeCountrySelected) {
      setSelectedCountry("Home");
    } else if (hostCountrySelected) {
      setSelectedCountry("Destination");
    } else {
      setSelectedCountry("");
    }
  }, [homeCountrySelected, hostCountrySelected]);

  useEffect(() => {
    // Set chartReady to true after component mounts
    setChartReady(true);
    return () => setChartReady(false);
  }, []);

  return (
    <div
      style={{
        borderRadius: "20px",
        height: "100%",
        boxShadow: " 0px 4px 40px 0px rgba(0, 0, 0, 0.1)",
      }}
      className="p-2 mt-2"
    >
      <h4 style={{ fontSize: "1rem" }} className="mt-2 mx-2">
        A1s filed vs Approved
        {selectedCountry ? (
          <>
            : <span style={{ color: primaryColor }}> {selectedCountry} </span>{" "}
            countries
          </>
        ) : (
          " countries"
        )}
      </h4>
      {series[0]?.data.length === 0 ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          No data available
        </div>
      ) : (
        chartReady && (
          <Chart
            options={options}
            series={series}
            type="bar"
            height={"100%"}
            width={"100%"}
          />
        )
      )}
    </div>
  );
};

export default DoubleBarGraph;
